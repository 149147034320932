import { Dayjs } from 'dayjs';
import { ReactNode } from 'react';
import { Select } from 'antd';
import {
  IEachDropdownElementsType,
  ICustomNPIList,
  IEsParams,
  IDebounceDropdownElements,
  DateRangeType,
} from '../../../../models/interfaces';
import {
  AgeSlider,
  CommonElementLabel,
  DateDropdown,
  DebounceDropdown,
  InputField,
  Multi,
  Single,
  UploadUserFiles,
  MultiCustomTaxonomy,
} from '../../../../components/sharedComponent';
import DMASelector from '../../../../components/pages/AudienceSetup/AudienceSetupComponents/DMASelector';
import DeleteTargetingElement from '../../../../components/sharedComponent/Delete/DeleteTargetingElementV1';
import { DeleteOutlined } from '@ant-design/icons';

type FeatureSetType = {
  value: number;
  title: string;
  fieldName: string;
  component: Function;
};

export const featureSetList: FeatureSetType[] = [
  {
    value: 1,
    title: 'Timeframes',
    component: callTimeframeDropdown,
    fieldName: 'timeFrame',
  },
  {
    value: 2,
    title: 'Patient Gender',
    component: callSingleSelector,
    fieldName: 'patientGender',
  },
  {
    value: 3,
    title: 'Patient Age',
    component: callPatientAgeSlider,
    fieldName: 'selectedAge',
  },
  {
    value: 4,
    title: 'Race & Ethnicity',
    component: callMultiSelector,
    fieldName: 'selectedRaceAndEthnicity',
  },
  {
    value: 5,
    title: 'Geography(State)',
    component: callMultiSelector,
    fieldName: 'selectedGeographyStates',
  },
  {
    value: 6,
    title: 'Provider Specialty',
    component: callMultiSelector,
    fieldName: 'selectedProviderSpecialty',
  },
  {
    value: 7,
    title: 'Custom NPI List',
    component: callUploadNpiList,
    fieldName: 'customNPIList',
  },
  {
    value: 8,
    title: 'Hospital',
    component: callMultiSelector,
    fieldName: 'selectedHospitals',
  },
  {
    value: 9,
    title: 'Diagnosis',
    component: callDebounceSelector,
    fieldName: 'selectedDiagnosis',
  },
  {
    value: 10,
    title: 'Drug',
    component: callDebounceSelector,
    fieldName: 'selectedDrugs',
  },
  {
    value: 11,
    title: 'Procedure',
    component: callDebounceSelector,
    fieldName: 'selectedProcedures',
  },
  {
    value: 12,
    title: 'Payer',
    component: callSingleSelector,
    fieldName: 'selectedInsurancePlanType',
  },
  {
    value: 13,
    title: 'Additional Options',
    component: callMultiSelector,
    fieldName: 'selectedAdditionalOpts',
  },
  {
    value: 14,
    title: 'Additional Information',
    component: callInputField,
    fieldName: 'additionalInfo',
  },
  {
    value: 15,
    title: 'Insurance',
    component: callDMASelector,
    fieldName: 'selectedGeographyDmas',
  },
  {
    value: 16,
    title: 'Insurance',
    component: callMultiSelector,
    fieldName: 'selectedInsuranceProviders',
  },
  {
    value: 18,
    title: 'Generic Drug',
    component: callDebounceSelector,
    fieldName: 'genericDrug',
  },
  {
    value: 19,
    title: 'Language',
    component: callMultiSelector,
    fieldName: 'language',
  },
  {
    value: 20,
    title: 'Provider Taxonomy',
    component: callMultiSelectorForTaxonomy,
    fieldName: 'selectedProviderTaxonomy',
  },
  {
    value: 21,
    title: 'CareGiver Gender',
    component: callSingleSelector,
    fieldName: 'caregiverGender',
  },
  {
    value: 22,
    title: 'CareGiver Age',
    component: callPatientAgeSlider,
    fieldName: 'caregiverAge',
  },
];

function callSingleSelector(props: {
  setId: number;
  label: string;
  options: IEachDropdownElementsType[];
  placeholder: string;
  isSearchable: boolean;
  is_feature?: boolean;
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  onChange: React.Dispatch<React.SetStateAction<number | null>>;
  handleSetDelete: Function;
  otherProps?: any;
  featureSetNumber: number;
}) {
  const {
    setId,
    label,
    options,
    placeholder,
    isSearchable,
    is_feature,
    componentSpecificClasses,
    isDisabled,
    onChange,
    handleSetDelete,
    otherProps,
    featureSetNumber,
  } = props;

  return (
    <div className="featuresetElementSingle">
      <div className="featureSetElementSingleLeft">
        {is_feature ? (
          <Select options={[]} placeholder={label} disabled />
        ) : (
          <CommonElementLabel label={label} />
        )}
        <Single
          options={options}
          placeholder={placeholder}
          isSearchable={isSearchable}
          onChange={onChange}
          componentSpecificClasses={componentSpecificClasses}
          isDisabled={isDisabled}
          props={otherProps}
          allowClear={false}
          targetingSetNumber={setId}
          featureSetNumber={featureSetNumber}
        />
      </div>

      {!isDisabled && (
        <>
          {setId === 12 ? (
            <DeleteOutlined
              className="deleteIconForElements"
              onClick={() => handleSetDelete(setId)}
            />
          ) : (
            <DeleteTargetingElement
              deleteSetParams={{
                setId,
                name:
                  featureSetList.find(
                    (el: FeatureSetType) => el.value === setId
                  )?.title ?? '',
                showRemove: true,
                handleDelete: handleSetDelete,
                fieldName: featureSetList.find(
                  (el: FeatureSetType) => el.value === setId
                )?.fieldName,
              }}
            />
          )}
        </>
      )}
    </div>
  );
}

function callTimeframeDropdown(props: {
  setId: number;
  label: string;
  isMandatory: boolean;
  options: IEachDropdownElementsType[];
  placeholder: string;
  isSearchable: boolean;
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  timeFrame: number | null;
  dateRange: DateRangeType;
  is_feature?: boolean;
  setTimeFrame: React.Dispatch<React.SetStateAction<number | null>>;
  setDateRange: React.Dispatch<React.SetStateAction<DateRangeType>>;
  handleSetDelete: Function;
  minCustomDate?: Dayjs;
  featureSetNumber: number;
}) {
  const {
    setId,
    label,
    isMandatory,
    options,
    placeholder,
    isSearchable,
    timeFrame,
    dateRange,
    setTimeFrame,
    setDateRange,
    handleSetDelete,
    minCustomDate,
    featureSetNumber,
    isDisabled,
    is_feature = false,
  } = props;
  return (
    <div className="featuresetElementSingle">
      <div className="featureSetElementSingleLeft">
        <DateDropdown
          label={label}
          isMandatory={isMandatory}
          options={options}
          placeholder={placeholder}
          isSearchable={isSearchable}
          timeFrame={timeFrame}
          dateRange={dateRange}
          is_feature={is_feature}
          setTimeFrame={setTimeFrame}
          setDateRange={setDateRange}
          minDate={minCustomDate}
          isDisabled={isDisabled}
          targetingSetNumber={setId}
          featureSetNumber={featureSetNumber}
        />
      </div>

      {!isDisabled && (
        <DeleteTargetingElement
          deleteSetParams={{
            setId,
            name:
              featureSetList.find((el: FeatureSetType) => el.value === setId)
                ?.title ?? '',
            showRemove: true,
            handleDelete: handleSetDelete,
            fieldName: featureSetList.find(
              (el: FeatureSetType) => el.value === setId
            )?.fieldName,
          }}
        />
      )}
    </div>
  );
}

function callPatientAgeSlider(props: {
  setId: number;
  label: string;
  is_feature?: boolean;
  defaultValue: number[];
  onChange: React.Dispatch<React.SetStateAction<number[]>>;
  handleSetDelete: Function;
  isDisabled?: boolean;
}) {
  const {
    setId,
    label,
    is_feature,
    defaultValue,
    onChange,
    handleSetDelete,
    isDisabled,
  } = props;
  return (
    <div className="featuresetElementSingle">
      <div className="featureSetElementSingleLeft">
        {is_feature ? (
          <Select options={[]} placeholder={label} disabled />
        ) : (
          <CommonElementLabel
            label={label}
            specificWrapperClass="ageSliderLabel"
          />
        )}
        <AgeSlider
          defaultValue={defaultValue}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      </div>

      {!isDisabled && (
        <DeleteTargetingElement
          deleteSetParams={{
            setId,
            name:
              featureSetList.find((el: FeatureSetType) => el.value === setId)
                ?.title ?? '',
            showRemove: true,
            handleDelete: handleSetDelete,
            fieldName: featureSetList.find(
              (el: FeatureSetType) => el.value === setId
            )?.fieldName,
          }}
        />
      )}
    </div>
  );
}

function callMultiSelector(props: {
  setId: number;
  label: string;
  isMandatory: boolean;
  options: IEachDropdownElementsType[];
  placeholder: string;
  isSearchable?: boolean;
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  is_feature?: boolean;
  defaultPopupWidth?: number | boolean;
  defaultPopupClassName?: string;
  otherProperties?: any;
  value: (number | string)[];
  onChange: React.Dispatch<React.SetStateAction<(number | string)[]>>;
  handleSetDelete: Function;
  featureSetNumber: number;
}) {
  const {
    setId,
    label,
    options,
    placeholder,
    isSearchable,
    is_feature,
    componentSpecificClasses,
    isDisabled,
    defaultPopupWidth,
    defaultPopupClassName,
    otherProperties,
    onChange,
    handleSetDelete,
    featureSetNumber,
  } = props;
  return (
    <div className="featuresetElementSingle">
      <div className="featureSetElementSingleLeft">
        {is_feature ? (
          <Select options={[]} placeholder={label} disabled />
        ) : (
          <CommonElementLabel label={label} />
        )}
        <Multi
          options={options}
          placeholder={placeholder}
          isSearchable={isSearchable}
          componentSpecificClasses={componentSpecificClasses}
          isDisabled={isDisabled}
          defaultPopupWidth={defaultPopupWidth}
          defaultPopupClassName={defaultPopupClassName}
          props={otherProperties}
          onChange={onChange}
          targetingSetNumber={setId}
          featureSetNumber={featureSetNumber}
        />
      </div>

      {!isDisabled && (
        <>
          {setId === 16 ? (
            <DeleteOutlined
              className="deleteIconForElements"
              onClick={() => handleSetDelete(setId)}
            />
          ) : (
            <DeleteTargetingElement
              deleteSetParams={{
                setId,
                name:
                  featureSetList.find(
                    (el: FeatureSetType) => el.value === setId
                  )?.title ?? '',
                showRemove: true,
                handleDelete: handleSetDelete,
                fieldName: featureSetList.find(
                  (el: FeatureSetType) => el.value === setId
                )?.fieldName,
              }}
            />
          )}
        </>
      )}
    </div>
  );
}

function callUploadNpiList(props: {
  setId: number;
  label: string;
  uuid: number;
  is_feature?: boolean;
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  customNPIList: ICustomNPIList[];
  setCustomNPIList: React.Dispatch<React.SetStateAction<ICustomNPIList[]>>;
  handleSetDelete: Function;
  featureSetNumber: number;
}) {
  const {
    setId,
    label,
    uuid,
    componentSpecificClasses,
    isDisabled,
    is_feature,
    customNPIList,
    setCustomNPIList,
    handleSetDelete,
    featureSetNumber,
  } = props;
  return (
    <div className="featuresetElementSingle">
      <div className="featureSetElementSingleLeft">
        {is_feature ? (
          <Select options={[]} placeholder={label} disabled />
        ) : (
          <CommonElementLabel label={label} />
        )}
        <UploadUserFiles
          uuid={uuid}
          componentSpecificClasses={componentSpecificClasses}
          customNPIList={customNPIList}
          setCustomNPIList={setCustomNPIList}
          isDisabled={isDisabled}
          targetingSetNumber={setId}
          featureSetNumber={featureSetNumber}
        />
      </div>

      {!isDisabled && (
        <DeleteTargetingElement
          deleteSetParams={{
            setId,
            name:
              featureSetList.find((el: FeatureSetType) => el.value === setId)
                ?.title ?? '',
            showRemove: true,
            handleDelete: handleSetDelete,
            fieldName: featureSetList.find(
              (el: FeatureSetType) => el.value === setId
            )?.fieldName,
          }}
        />
      )}
    </div>
  );
}

function callInputField(props: {
  setId: number;
  label: string;
  isMandatory?: boolean;
  inputWrapperClasses?: string[];
  fieldType?: 'input' | 'textarea';
  inputPlaceholder?: string;
  ifAllowCharCount?: boolean;
  ifAllowClear?: boolean;
  additionalInfo: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  fieldVariant?: 'outlined' | 'borderless' | 'filled';
  prefix?: ReactNode;
  suffix?: ReactNode;
  isDisabled?: boolean;
  otherProperties?: any;
  handleSetDelete: Function;
  featureSetNumber: number;
}) {
  const {
    setId,
    label,
    isMandatory = false,
    inputWrapperClasses = [],
    fieldType = 'input',
    inputPlaceholder = 'Text',
    ifAllowCharCount = false,
    ifAllowClear = false,
    additionalInfo,
    onChange,
    fieldVariant = 'outlined',
    prefix = <></>,
    suffix = <></>,
    isDisabled = false,
    otherProperties = {},
    handleSetDelete,
    featureSetNumber,
  } = props;
  return (
    <div className="featuresetElementSingle">
      <div className="featureSetElementSingleLeft">
        <InputField
          label={label}
          isMandatory={isMandatory}
          inputWrapperClasses={inputWrapperClasses}
          ifAllowCharCount={ifAllowCharCount}
          ifAllowClear={ifAllowClear}
          fieldType={fieldType}
          inputPlaceholder={inputPlaceholder}
          onChange={onChange}
          fieldVariant={fieldVariant}
          prefix={prefix}
          suffix={suffix}
          isDisabled={isDisabled}
          props={otherProperties}
          defaultValue={additionalInfo}
          targetingSetNumber={setId}
          featureSetNumber={featureSetNumber}
        />
      </div>

      {!isDisabled && (
        <DeleteTargetingElement
          deleteSetParams={{
            setId,
            name:
              featureSetList.find((el: FeatureSetType) => el.value === setId)
                ?.title ?? '',
            showRemove: true,
            handleDelete: handleSetDelete,
            fieldName: featureSetList.find(
              (el: FeatureSetType) => el.value === setId
            )?.fieldName,
          }}
        />
      )}
    </div>
  );
}

function callDebounceSelector(props: {
  setId: number;
  label: string;
  is_feature?: boolean;
  placeholder: string;
  esParams: IEsParams;
  value: IDebounceDropdownElements[];
  onChange: React.Dispatch<React.SetStateAction<IDebounceDropdownElements[]>>;
  handleSetDelete: (id: number) => void;
  featureSetNumber: number;
  isDisabled?: boolean;
}) {
  const {
    setId,
    label,
    is_feature,
    placeholder,
    esParams,
    value,
    onChange,
    handleSetDelete,
    featureSetNumber,
    isDisabled,
  } = props;

  return (
    <div className="featuresetElementSingle">
      <div className="featureSetElementSingleLeft">
        {is_feature ? (
          <Select options={[]} placeholder={label} disabled />
        ) : (
          <CommonElementLabel label={label} />
        )}
        <DebounceDropdown
          placeholder={placeholder}
          esParams={esParams}
          onChange={onChange}
          defaultValue={value}
          targetingSetNumber={setId}
          isDisabled={isDisabled}
          featureSetNumber={featureSetNumber}
          ifShowValue={label !== 'Generic Drug'}
        />
      </div>

      {!isDisabled && (
        <DeleteOutlined
          className="deleteIconForElements"
          onClick={() => handleSetDelete(setId)}
        />
      )}
    </div>
  );
}

function callDMASelector(props: {
  setId: number;
  label: string;
  is_feature?: boolean;
  isSearchable?: boolean;
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  defaultPopupWidth?: number | boolean;
  defaultPopupClassName?: string;
  onChange: React.Dispatch<React.SetStateAction<(number | string)[]>>;
  handleSetDelete: Function;
  featureSetNumber: number;
  value: (number | string)[];
}) {
  const {
    setId,
    label,
    is_feature,
    isSearchable,
    componentSpecificClasses,
    isDisabled,
    defaultPopupWidth,
    defaultPopupClassName,
    onChange,
    handleSetDelete,
    featureSetNumber,
    value,
  } = props;
  return (
    <div className="featuresetElementSingle">
      <div className="featureSetElementSingleLeft">
        {is_feature ? (
          <Select options={[]} placeholder={label} disabled />
        ) : (
          <CommonElementLabel label={label} />
        )}
        <DMASelector
          isSearchable={isSearchable}
          componentSpecificClasses={componentSpecificClasses}
          isDisabled={isDisabled}
          defaultPopupWidth={defaultPopupWidth}
          defaultPopupClassName={defaultPopupClassName}
          onChange={onChange}
          featureSetNumber={featureSetNumber}
          targetingSetNumber={setId}
          value={value}
        />
      </div>

      {!isDisabled && (
        <DeleteTargetingElement
          deleteSetParams={{
            setId,
            name:
              featureSetList.find((el: FeatureSetType) => el.value === setId)
                ?.title ?? '',
            showRemove: true,
            handleDelete: handleSetDelete,
            fieldName: featureSetList.find(
              (el: FeatureSetType) => el.value === setId
            )?.fieldName,
          }}
        />
      )}
    </div>
  );
}

function callMultiSelectorForTaxonomy(props: {
  setId: number;
  label: string;
  is_feature?: boolean;
  options: IEachDropdownElementsType[];
  placeholder: string;
  isSearchable?: boolean;
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  defaultPopupWidth?: number | boolean;
  defaultPopupClassName?: string;
  otherProperties?: any;
  value: (number | string)[];
  onChange: React.Dispatch<React.SetStateAction<(number | string)[]>>;
  handleSetDelete: Function;
  featureSetNumber: number;
}) {
  const {
    setId,
    label,
    is_feature,
    options,
    placeholder,
    isSearchable,
    componentSpecificClasses,
    isDisabled,
    defaultPopupWidth,
    defaultPopupClassName,
    otherProperties,
    onChange,
    handleSetDelete,
    featureSetNumber,
  } = props;
  return (
    <div className="featuresetElementSingle">
      <div className="featureSetElementSingleLeft">
        {is_feature ? (
          <Select options={[]} placeholder={label} disabled />
        ) : (
          <CommonElementLabel label={label} />
        )}
        <MultiCustomTaxonomy
          options={options}
          placeholder={placeholder}
          isSearchable={isSearchable}
          componentSpecificClasses={componentSpecificClasses}
          isDisabled={isDisabled}
          defaultPopupWidth={defaultPopupWidth}
          defaultPopupClassName={defaultPopupClassName}
          props={otherProperties}
          onChange={onChange}
          targetingSetNumber={setId}
          featureSetNumber={featureSetNumber}
        />
      </div>

      {!isDisabled && (
        <DeleteTargetingElement
          deleteSetParams={{
            setId,
            name:
              featureSetList.find((el: FeatureSetType) => el.value === setId)
                ?.title ?? '',
            showRemove: true,
            handleDelete: handleSetDelete,
            fieldName: featureSetList.find(
              (el: FeatureSetType) => el.value === setId
            )?.fieldName,
          }}
        />
      )}
    </div>
  );
}
