import { FC, useState } from 'react';
import { MenuProps, Button, Menu, Tooltip } from 'antd';
import {
  LeftPanelHeader,
  LeftPanelProfileMenu,
  LeftPanelMainMenu,
  LeftPanelPixelMenu,
  // LeftPanelResearch,
} from './';
import { useRedirection } from '../../../hooks';
import {
  audience,
  collapsedLogo,
  collapsedArrow,
  // research,
  pixel,
  settings,
  accountManagement,
} from '../../../assets/images';
import LeftPanelSettings from './LeftPanelSettings';
import LeftPanelAccountManagement from './LeftPanelAccountManagement';
import { useUserInformationStore } from '../../../stores';
import { IUserInformationState } from '../../../models/interfaces';
import { RbacSettings } from '../../../models/enums/rbacSetting';
import { useNavigate } from 'react-router-dom';

type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  disabled?: boolean,
  onClick?: () => void,
  children?: any
): MenuItem {
  return {
    label,
    key,
    icon,
    disabled,
    onClick,
    children,
  } as MenuItem;
}

interface LeftPanelProps {
  defaultSelectedKeys?: string[];
}

interface LevelKeysProps {
  key?: string;
  children?: LevelKeysProps[];
}

const getLevelKeys = (items1: LevelKeysProps[]) => {
  const key: Record<string, number> = {};
  const func = (items2: LevelKeysProps[], level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};

const LeftPanel: FC<LeftPanelProps> = ({ defaultSelectedKeys }) => {
  const navigate = useNavigate();

  const getIsCollapseStorage = JSON.parse(
    localStorage.getItem('collapse') ?? '0'
  );
  const getIsSubCollapse = JSON.parse(
    localStorage.getItem('subCollapse') ?? '2'
  );

  const [collapsed, setCollapsed] = useState(Boolean(getIsCollapseStorage)),
    [subCol, setSubCol] = useState(Boolean(getIsSubCollapse)),
    [stateOpenKeys, setStateOpenKeys] = useState([
      (() => {
        if (!collapsed && subCol) {
          return `${getIsSubCollapse}`;
        }
        return '';
      })(),
    ]);

  const { privilegeSetDDElements } = useUserInformationStore(
    (state: IUserInformationState) => state
  );

  const {
    redirectAudienceListing,
    redirectAudienceActivations,
    redirectPixelListing,
    // redirectResearch,
    redirectAccountListing,
  } = useRedirection();

  const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );

    if (currentOpenKey) {
      localStorage.setItem('subCollapse', currentOpenKey);
      setSubCol(false);
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      localStorage.setItem('subCollapse', '0');
      setSubCol(true);
      setStateOpenKeys(openKeys);
    }
  };

  const items: MenuItem[] = [
    getItem(
      <LeftPanelHeader />,
      '1',
      <img src={collapsedLogo} className="collapsedLogo" alt="" />,
      true
    ),
    getItem(
      <LeftPanelMainMenu />,
      '2',
      <img src={audience} className="" alt="" />,
      false,
      () => {},
      [
        {
          key: '21',
          label: 'View All',
          onClick: redirectAudienceListing,
        },
        {
          key: '22',
          label: 'Activations',
          onClick: redirectAudienceActivations,
        },
      ]
    ),
    /**getItem(
      <LeftPanelActiveAudience />,
      '3',
      <Tooltip placement="right" title="Activation">
        <img src={activeAudience} className="" alt="" />
      </Tooltip>,
      false,
      redirectAudienceActivations
    ),
    getItem(
      <LeftPanelResearch />,
      '4',
      <Tooltip placement="right" title="Research">
        <img src={research} className="" alt="" />
      </Tooltip>,
      false,
      redirectResearch
    ),*/
    getItem(
      <LeftPanelPixelMenu />,
      '5',
      <Tooltip placement="right" title="Conversion Pixels">
        <img src={pixel} className="" alt="" />
      </Tooltip>,
      false,
      redirectPixelListing
    ),
    getItem(
      <LeftPanelSettings />,
      '7',
      <img src={settings} className="" alt="" />,
      false,
      () => {},
      [
        {
          key: '71',
          label: 'Account',
          onClick: () => navigate('/account-settings'),
        },
        {
          key: '72',
          label: 'Users',
          onClick: () => navigate('/settings/user'),
        },
        {
          key: '73',
          label: 'Organization',
          onClick: () => navigate('/settings/organization'),
        },
        {
          key: '74',
          label: 'Audit',
          onClick: () => navigate('/settings/audit'),
        },
      ]
    ),
    getItem(
      <LeftPanelAccountManagement />,
      '8',
      <Tooltip placement="right" title="Account Management">
        <img src={accountManagement} className="" alt="" />
      </Tooltip>,
      false,
      redirectAccountListing
    ),
    getItem('', 'setting', <LeftPanelProfileMenu />),
  ];

  const items2: MenuItem[] = [
    getItem(
      <LeftPanelHeader />,
      '1',
      <img src={collapsedLogo} className="collapsedLogo" alt="" />,
      true
    ),
    getItem(
      <LeftPanelMainMenu />,
      '2',
      <img src={audience} className="" alt="" />,
      false,
      () => {},
      [
        {
          key: '21',
          label: 'View All',
          onClick: redirectAudienceListing,
        },
        {
          key: '22',
          label: 'Activations',
          onClick: redirectAudienceActivations,
        },
      ]
    ),
    /**getItem(
      <LeftPanelActiveAudience />,
      '3',
      <Tooltip placement="right" title="Activation">
        <img src={activeAudience} className="" alt="" />
      </Tooltip>,
      false,
      redirectAudienceActivations
    ),
    getItem(
      <LeftPanelResearch />,
      '4',
      <Tooltip placement="right" title="Research">
        <img src={research} className="" alt="" />
      </Tooltip>,
      false,
      redirectResearch
    ),*/
    getItem(
      <LeftPanelPixelMenu />,
      '5',
      <Tooltip placement="right" title="Conversion Pixels">
        <img src={pixel} className="" alt="" />
      </Tooltip>,
      false,
      redirectPixelListing
    ),
    getItem('', 'setting', <LeftPanelProfileMenu />),
  ];

  const items3: MenuItem[] = [
    getItem(
      <LeftPanelHeader />,
      '1',
      <img src={collapsedLogo} className="collapsedLogo" alt="" />,
      true
    ),
    getItem(
      <LeftPanelMainMenu />,
      '2',
      <img src={audience} className="" alt="" />,
      false,
      () => {},
      [
        {
          key: '21',
          label: 'View All',
          onClick: redirectAudienceListing,
        },
        {
          key: '22',
          label: 'Activations',
          onClick: redirectAudienceActivations,
        },
      ]
    ),
    /**getItem(
      <LeftPanelActiveAudience />,
      '3',
      <Tooltip placement="right" title="Activation">
        <img src={activeAudience} className="" alt="" />
      </Tooltip>,
      false,
      redirectAudienceActivations
    ),
    getItem(
      <LeftPanelResearch />,
      '4',
      <Tooltip placement="right" title="Research">
        <img src={research} className="" alt="" />
      </Tooltip>,
      false,
      redirectResearch
    ),*/
    getItem(
      <LeftPanelPixelMenu />,
      '5',
      <Tooltip placement="right" title="Conversion Pixels">
        <img src={pixel} className="" alt="" />
      </Tooltip>,
      false,
      redirectPixelListing
    ),
    getItem(
      <LeftPanelSettings />,
      '7',
      <img src={settings} className="" alt="" />,
      false,
      () => {},
      [
        {
          key: '71',
          label: 'Account',
          onClick: () => navigate('/account-settings'),
        },
        {
          key: '72',
          label: 'Users',
          onClick: () => navigate('/settings/user'),
        },
        {
          key: '73',
          label: 'Organization',
          onClick: () => navigate('/settings/organization'),
        },
        {
          key: '74',
          label: 'Audit',
          onClick: () => navigate('/settings/audit'),
        },
      ]
    ),
    getItem('', 'setting', <LeftPanelProfileMenu />),
  ];

  const levelKeys = getLevelKeys(items as LevelKeysProps[]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    localStorage.setItem('collapse', !collapsed === true ? '1' : '0');
  };

  return (
    <div
      style={{ width: collapsed ? '100px' : '282px' }}
      className={`leftPanelWrap${collapsed ? ' leftPanelCollapsed' : ''} ${
        process.env.REACT_APP_ENV === 'dev' ? 'leftPanelDev' : ''
      }`}
    >
      <Button
        type="primary"
        onClick={toggleCollapsed}
        className={` ${
          collapsed ? 'collapsedStatecCollapseBtn' : 'collapseBtn'
        }`}
      >
        <img src={collapsedArrow} alt="" />
      </Button>
      <Menu
        defaultSelectedKeys={defaultSelectedKeys}
        mode="inline"
        inlineCollapsed={collapsed}
        triggerSubMenuAction="click"
        items={(() => {
          switch (true) {
            case privilegeSetDDElements?.includes(
              RbacSettings.ACCOUNT_MANAGEMENT_VIEW
            ):
              return items;
            case privilegeSetDDElements?.includes(
              RbacSettings.ACCOUNT_SETTINGS_VIEW
            ):
              return items3;
            default:
              return items2;
          }
        })()}
        openKeys={stateOpenKeys}
        onOpenChange={onOpenChange}
        className="leftPanelMenu"
      />
    </div>
  );
};

export default LeftPanel;
