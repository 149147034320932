import React, { Fragment, useEffect, useState } from 'react';
import { PageHeader } from '../../../sharedComponent';
import {
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useLoaderStore,
  // useMasterAdditionalOptionElementsStore,
  useMasterAudienceTypeElementsStore,
  useUserInformationStore,
} from '../../../../stores';
import {
  IAudienceSetUpState,
  IEachDropdownElementsType,
  IAudienceState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IFeatureSetElement,
  ILoaderState,
  // IMasterAdditionalOptionDDState,
  IMasterAdvertiserDDState,
  IMasterAgencyDDState,
  IMasterAudienceTypeDDState,
  IMasterBrandDDState,
  IMasterPixelDDState,
  IUserInformationState,
} from '../../../../models/interfaces';
import {
  AdminOptions,
  CareGiverFeatureSet,
  DefaultFeatureSet,
  DefinedFeatureSet,
  GlobalFeatureSet,
  HcpFeatureSet,
} from '../../AudienceSetup/AudienceSetupComponents';
import {
  useAudienceSets,
  useAudienceSetUp,
} from '../../../../stores/audienceSets.store';
import { Button, Collapse, CollapseProps } from 'antd';
import {
  useNotificationMessage,
  usePostApiInterceptor,
  useRedirection,
} from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import {
  useMasterAdvertiserElementStore,
  useMasterAgencyElementStore,
  useMasterBrandElementStore,
  useMasterPixelElementsStore,
} from '../../../../stores/masterData.store';
import { CaretRightOutlined } from '@ant-design/icons';

function separateFeatureSetsByTargetingId(data: any): {
  withTargetingId: any[];
  withoutTargetingId: any[];
  withCaregiver: any;
  withHcp: any;
} {
  const withTargetingId: any[] = [];
  const withoutTargetingId: any[] = [];
  let withCaregiver: any = null;
  let withHcp: any = null;

  data.forEach((featureSet: any) => {
    if (
      featureSet.targeting.some(
        (target: any) =>
          target.targeting_id === 1 ||
          target.targeting_id === 2 ||
          target.targeting_id === 3 ||
          target.targeting_id === 4 ||
          target.targeting_id === 5 ||
          target.targeting_id === 15 ||
          target.targeting_id === 19
      )
    ) {
      withTargetingId.push(featureSet);
    } else if (featureSet.is_caregiver) {
      withCaregiver = featureSet;
    } else if (featureSet.is_hcp) {
      withHcp = featureSet;
    } else {
      withoutTargetingId.push(featureSet);
    }
  });

  return { withTargetingId, withoutTargetingId, withCaregiver, withHcp };
}

interface AdminOption {
  admin_option_id: number;
  is_search_for_treatments: boolean;
  drugs_count: number;
  is_distinct: boolean;
  updated_on?: string;
  is_updating?: boolean;
}

type TOrganisation = 'agency' | 'brand' | 'advertiser';

const AudienceWrap = () => {
  const { loaderState } = useLoaderStore((state: ILoaderState) => state),
    { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { agencyDDElements } = useMasterAgencyElementStore(
      (state: IMasterAgencyDDState) => state
    ),
    { brandDDElements } = useMasterBrandElementStore(
      (state: IMasterBrandDDState) => state
    ),
    { advertiserDDElements } = useMasterAdvertiserElementStore(
      (state: IMasterAdvertiserDDState) => state
    ),
    { pixelDDElements } = useMasterPixelElementsStore(
      (state: IMasterPixelDDState) => state
    ),
    { userInformation } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { redirectAudienceListing } = useRedirection(),
    { firstFeatureNumber, setFirstFeatureNumber } = useAudienceSets(
      (state: IAudienceState) => state
    ),
    {
      audienceTitle,
      audienceBrand,
      audienceAgency,
      setAudienceAgency,
      setAudienceBrand,
      setAudienceTitle,
      advertiser,
      setAdvertiser,
    } = useAudienceSetUp((state: IAudienceSetUpState) => state);

  const [selectedAudienceType, setSelectedAudienceType] = useState<
      number | null
    >(null),
    [selectedAdditionalOpts, setSelectedAdditionalOpts] =
      useState<boolean>(false),
    [careGiverSet, setCareGiverSet] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    }),
    [hcpFeatureSet, setHcpFeatureSet] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    }),
    [body, setBody] = useState<any>(null),
    [prevSetId, setPrevSetId] = useState<number>(0),
    [featureSetCount, setFeatureSetCount] = useState<number>(1),
    [featureSets, setFeatureSets] = useState<IFeatureSetElement[]>([]),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [pixelData, setPixelData] = useState<any>([]),
    [pixelFieldData, setPixelFieldData] = useState<any>(''),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [pixelName, setPixelName] = useState<string | null>(null),
    [pixelId, setPixelId] = useState<number>(0),
    [adminData, setAdminData] = useState<AdminOption>({
      admin_option_id: 0,
      is_search_for_treatments: false,
      drugs_count: 0,
      is_distinct: false,
    }),
    [organisationType, setOrganisationType] = useState<TOrganisation>('agency'),
    [globalFeatureSets, setGlobalFeatureSets] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    });

  useEffect(() => {
    return () => {
      setAudienceTitle('');
      setAudienceBrand(null);
      setAdvertiser(null);
      setAudienceAgency(null);
      setFeatureSets([]);
      setFeatureSetCount(1);
      setSelectedAudienceType(null);
      setFirstFeatureNumber(0);
    };
  }, []);

  const { context, destroyMessage } = useNotificationMessage(messageObj);

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.SAVE_FEATURE_SET_DATA,
    (data: any, error: any) => {
      setBody('');
      setIsMounted(false);
      if (data && !error) {
        redirectAudienceListing();
        updateErrorMessage('success', 'Audience successful');
      } else {
        updateErrorMessage('error', 'Error occurred while saving Audience.');
      }
    }
  );

  const getItems: () => CollapseProps['items'] = () => [
    {
      key: '1',
      label: 'Admin Options',
      children: (
        <AdminOptions data={adminData} setData={setAdminData} disabled={true} />
      ),
    },
  ];

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration: 5,
    });
  };

  useEffect(() => {
    if (pixelDDElements.length > 0) {
      let pixelData = pixelDDElements;
      pixelData.push({
        value: 1000,
        label: 'None (Default)',
        order_id: 1000,
      });
      setPixelData(pixelData);
    }
  }, [pixelDDElements]);

  useEffect(() => {
    if (pixelName && pixelData.length > 0) {
      const filteredPixel = pixelData?.filter(
        (pix: any) => pix.label === pixelName
      );
      let temp = {
        label: filteredPixel[0]?.label ?? '',
        value: filteredPixel[0]?.label ?? '',
      };
      setPixelFieldData(temp);
    }
  }, [pixelName, pixelData]);

  useEffect(() => {
    if (ifUpdated) {
      const {
        title,
        audience_type_id,
        additional_option_id,
        agency_id: audience_agency,
        brand_id: audience_brand,
        advertiser_id: advertiser,
        admin_options,
        feature_sets,
        pixel_name,
      } = updatedDataSet;
      setPixelName(pixel_name);
      setAudienceAgency(audience_agency);
      setAudienceBrand(audience_brand);
      setAudienceTitle(title);
      setAdvertiser(advertiser);
      setSelectedAudienceType(audience_type_id);
      setSelectedAdditionalOpts(additional_option_id);
      if (admin_options) {
        setAdminData(admin_options);
      }
      const { withTargetingId, withoutTargetingId, withCaregiver, withHcp } =
        separateFeatureSetsByTargetingId(feature_sets);
      if (feature_sets?.length) {
        const newFeatureSets: IFeatureSetElement[] = [];
        let largestSetId = 0;
        withoutTargetingId
          .sort((a, b) => a.feature_set_rank - b.feature_set_rank)
          .forEach((el, index) => {
            if (index === 0 && !firstFeatureNumber) {
              setFirstFeatureNumber(el.feature_set_id);
            }
            largestSetId = Math.max(largestSetId, el.feature_set_id);
            newFeatureSets.push({
              setId: el.feature_set_id,
              orderId: index + 1,
            });
          });
        setPrevSetId(largestSetId + 1);
        setFeatureSetCount(withoutTargetingId.length);
        if (withHcp) {
          setHcpFeatureSet({
            setId: withHcp.feature_set_id,
            orderId: 1,
            element: createHcpElem(withHcp.feature_set_id, 1),
          });
        }
        if (withTargetingId?.length) {
          setGlobalFeatureSets({
            setId: withTargetingId.length
              ? withTargetingId[0].feature_set_id
              : 1,
            orderId: 1,
            element: createGlobalElem(
              withTargetingId.length ? withTargetingId[0].feature_set_id : 1,
              1
            ),
          });
        }
        setFeatureSets(
          createFeatureSet(newFeatureSets, withoutTargetingId.length)
        );
        if (withCaregiver && additional_option_id) {
          setCareGiverSet({
            setId: withCaregiver.feature_set_id,
            orderId: withCaregiver.order_id,
            element: createCareGiverElem(
              withCaregiver.feature_set_id,
              withCaregiver.order_id
            ),
          });
        }
      }
    }
  }, [ifUpdated]);

  const createHcpElem = (setId: number, orderId: number) => {
    return (
      <HcpFeatureSet
        featureSetNumber={setId}
        featureSetOrderNumber={orderId}
        disabled={true}
      />
    );
  };

  const createFeatureSet = (fs?: IFeatureSetElement[], fsCount?: number) => {
    const tempFeatureSets: IFeatureSetElement[] = fs?.length
        ? [...fs]
        : [...featureSets],
      newFeatureSets: IFeatureSetElement[] = [];

    for (let i = 0; i < (fsCount ?? featureSetCount); i++) {
      if (i < tempFeatureSets.length) {
        const { setId, orderId } = tempFeatureSets[i];
        newFeatureSets.push({
          setId: setId,
          orderId: orderId,
          element: createNewElem(setId, orderId, fsCount),
        });
      } else {
        const newSetId = prevSetId + 1;
        setPrevSetId(newSetId);

        newFeatureSets.push({
          setId: newSetId,
          orderId: i + 1,
          element: createNewElem(newSetId, i + 1),
        });
      }
    }
    return newFeatureSets;
  };

  const createNewElem = (
    setId: number,
    orderId: number,
    setCount: number | null = null
  ) => {
    return (
      <div>
        <DefinedFeatureSet
          featureSetNumber={setId}
          featureSetOrderNumber={orderId}
          disabled={true}
        />
      </div>
    );
  };

  const createCareGiverElem = (setId: number, orderId: number) => {
    return (
      <CareGiverFeatureSet
        featureSetNumber={setId}
        selectedAdditionalOpts={selectedAdditionalOpts}
        disabled={true}
      />
    );
  };

  const handleCancel = () => {
    setAudienceTitle('');
    setAudienceBrand(null);
    setAdvertiser(null);
    setAudienceAgency(null);
    setFeatureSets([]);
    setFeatureSetCount(1);
    setSelectedAudienceType(null);
    redirectAudienceListing();
  };

  const onChange = (value: any) => {
    if (value === 'None (Default)') {
      setPixelFieldData(`${value}`);
    } else {
      const filteredPixel: IEachDropdownElementsType[] = pixelDDElements.filter(
        (pix: any) => pix.value === value
      );
      setPixelFieldData(`${value}`);
      setPixelId(filteredPixel[0].order_id);
      setPixelName(filteredPixel[0].label);
    }
  };

  const handleSave = () => {
    destroyMessage('save');
    setMessageObj({ isShowing: false });
    const overAllData = {
      audience_id: updatedDataSet.audience_id,
      audience_type_id: updatedDataSet.audience_type_id,
      title: audienceTitle,
      agency_id: audienceAgency,
      brand_id: audienceBrand,
      advertiser_id: advertiser,
      status_id: updatedDataSet.status_id,
      pixel_id: pixelId,
      additional_option_id: selectedAdditionalOpts,
      feature_sets: updatedDataSet?.feature_sets?.length
        ? updatedDataSet?.feature_sets
        : [],
      is_edited: 1,
    };
    setBody(overAllData);
    setIsMounted(true);
  };

  const createGlobalElem = (setId: number, orderId: number) => {
    return (
      <GlobalFeatureSet
        featureSetNumber={setId}
        featureSetOrderNumber={orderId}
        disabled={true}
      />
    );
  };

  return (
    <>
      <div className="audienceCommonWrap">
        <PageHeader title="View Audience" />
        {context}
        {!loaderState && (
          <>
            <DefaultFeatureSet
              disabled={true}
              defaultTitle={audienceTitle}
              setTitle={setAudienceTitle}
              pixelData={pixelData}
              pixelFieldData={pixelFieldData}
              handlePixelChange={onChange}
              audienceType={selectedAudienceType}
              setAudienceType={(value: any) => setSelectedAudienceType(value)}
              advertiser={advertiser}
              setAdvertiser={setAdvertiser}
              defaultAgency={audienceAgency}
              setAgency={setAudienceAgency}
              defaultBrand={audienceBrand}
              agencyDDElements={agencyDDElements}
              brandDDElements={brandDDElements}
              advertiserDDElements={advertiserDDElements}
              setBrand={setAudienceBrand}
              selectedAdditionalOpts={selectedAdditionalOpts}
              setSelectedAdditionalOpts={setSelectedAdditionalOpts}
              audienceTypeDDElements={audienceTypeDDElements}
              setOrganisationType={setOrganisationType}
              openOrganisation={() => {}}
            />

            <div className="featureSetWholeWrapBlock">
              <div className="audienceDefinitionWrap">
                <h3>Audience Definition</h3>
                <div className="featureSetWhole pixelUrlSec adminoptionAccordion">
                  {userInformation.role === 4 && (
                    <Collapse
                      bordered={false}
                      defaultActiveKey={['']}
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                      )}
                      expandIconPosition="end"
                      items={getItems()}
                    />
                  )}
                </div>
                <div>{globalFeatureSets.element}</div>
                <div>{careGiverSet.element}</div>
                <div>{hcpFeatureSet.element}</div>
                {featureSetCount > 0 &&
                  featureSets.map((el: IFeatureSetElement) => (
                    <Fragment key={el.setId}>{el.element}</Fragment>
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="newFeatureSetBtn">
        <Button
          type="text"
          size="large"
          onClick={handleCancel}
          className="noBGBtn"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="large"
          disabled={
            !(
              selectedAudienceType &&
              audienceTitle &&
              audienceAgency &&
              audienceBrand &&
              advertiser
            )
          }
          className="dropdownSaveButton primaryBtn"
          onClick={handleSave}
          loading={isMounted}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default AudienceWrap;
