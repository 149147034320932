import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import {
  IAudienceSaveMethods,
  IAudienceState,
  ICareGiverFeatureSetBaseData,
  ICaregiverFeatureState,
  ICheckIfDataModifiedState,
  IDependentTargetingSelectedState,
  IEachDropdownElementsType,
  IEnableSaveState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IFeatureSetElement,
  IFeatureSetParamDatum,
  IMasterDmaDDState,
  IMasterGenderDDState,
  IMasterStateDDState,
  IMasterTargetingDDState,
} from '../../../../models/interfaces';
import { Select } from 'antd';
import {
  useCheckDependentTargetingSelectedStore,
  useCheckIfDataModifiedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useMasterDmaElementsStore,
  useMasterGenderElementsStore,
  useMasterStateElementsStore,
  useMasterTargetingElementsStore,
} from '../../../../stores';
import {
  useAudienceSaveMethods,
  useAudienceSets,
  useCareGiverFeatureSet,
} from '../../../../stores/audienceSets.store';
import { featureSetList } from './featureSetList';

const baseValues: ICareGiverFeatureSetBaseData = {
  caregiverGender: null,
  caregiverAge: [],
  selectedGeographyDmas: [],
  selectedGeographyStates: [],
};

const CareGiverFeatureSet: FC<{
  featureSetNumber: number;
  disabled?: boolean;
  selectedAdditionalOpts?: boolean;
}> = ({ disabled, featureSetNumber, selectedAdditionalOpts }) => {
  const [featureSetBody, setFeatureSetBody] = useState<IFeatureSetElement[]>(
    []
  );

  const targetableOptionsRef = useRef<any[]>([]);

  const { targetingDDElements } = useMasterTargetingElementsStore(
      (state: IMasterTargetingDDState) => state
    ),
    { genderDDElements } = useMasterGenderElementsStore(
      (state: IMasterGenderDDState) => state
    ),
    { stateDDElements } = useMasterStateElementsStore(
      (state: IMasterStateDDState) => state
    ),
    { setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    { updateIsDependentTargetingSelectedForEachSet } =
      useCheckDependentTargetingSelectedStore(
        (state: IDependentTargetingSelectedState) => state
      ),
    { updateDmaDDElements } = useMasterDmaElementsStore(
      (state: IMasterDmaDDState) => state
    ),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    {
      deletedIds,
      setDeletedIds,
      featureSetDataForMessage,
      setFeatureSetDataForMessage,
      setFeatureSetDataForMessagePrev,
    } = useAudienceSets((state: IAudienceState) => state),
    { updateSavingDataSet, updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { isSaveInitiated, setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    {
      caregiverFeatureSet,
      setCaregiverFeatureSet,
      updateCaregiverFeatureSet,
      caregiverTargeting,
      setCaregiverTargeting,
      updateCaregiverTargeting,
    } = useCareGiverFeatureSet((state: ICaregiverFeatureState) => state),
    {
      updateIisFeatureSetsFilled,
      isEnableSaveForEachSet,
      updateIsEnableSaveForEachSet,
    } = useCheckSavingEnabilityStore((state: IEnableSaveState) => state);

  const handleTargetableOptions = (value: number) => {
    if (value === 22) {
      let tempFeatureValue = { ...caregiverFeatureSet };
      tempFeatureValue['caregiverAge'] = [18, 100];
      setCaregiverFeatureSet(tempFeatureValue);
    }
    updateCaregiverTargeting(value);
    let updatedOptions = [...targetableOptionsRef.current].filter(
      (opt: IEachDropdownElementsType) => opt.value !== value
    );
    if (value === 5 || value === 15) {
      const disabledValue = value === 5 ? 15 : 5;
      updatedOptions = updatedOptions.map((opt: any) =>
        opt.value === disabledValue ? { ...opt, disabled: true } : opt
      );
    }
    targetableOptionsRef.current = [...updatedOptions];
  };

  useEffect(() => {
    updateDmaDDElements(featureSetNumber, [], stateDDElements);
  }, []);

  const checkSaveEnabled = () => {
    switch (true) {
      case !caregiverTargeting?.length:
        return 0;
      case caregiverTargeting.includes(21) &&
        !caregiverFeatureSet.caregiverGender:
      case caregiverTargeting.includes(5) &&
        !caregiverFeatureSet.selectedGeographyStates.length:
      case caregiverTargeting.includes(15) &&
        !caregiverFeatureSet.selectedGeographyDmas.length:
        return 1;
      default:
        return 2;
    }
  };

  useEffect(() => {
    if (
      ifUpdated &&
      updatedDataSet?.feature_sets?.length &&
      caregiverTargeting.length === 0
    ) {
      const featureSet = updatedDataSet.feature_sets.find(
        (el: any) => el.feature_set_id === featureSetNumber && el.is_caregiver
      );
      if (featureSet) {
        const params = getParams(),
          duals: any = { 17: 1, 16: 12 },
          targetingSet = featureSet.targeting.sort(
            (a: any, b: any) => a.targeting_id - b.targeting_id
          );

        let newTargetingSet: any = [];
        let tempFeatureSetDataForMessage = {};

        targetingSet.forEach((el: any) => {
          const keys = Object.keys(duals);

          if (keys.includes(el.targeting_id.toString())) {
            const index: number = newTargetingSet.findIndex(
              (elem: any) => elem.targeting_id === duals[`${el.targeting_id}`]
            );

            newTargetingSet[index] = {
              ...newTargetingSet[index],
              targeting_value: [
                ...newTargetingSet[index].targeting_value,
                JSON.parse(el.targeting_value),
              ],
              uniqueIds: [...newTargetingSet[index].uniqueIds, el.targeting_id],
            };
          } else {
            newTargetingSet.push({
              ...el,
              targeting_value:
                el.targeting_id !== 14
                  ? [JSON.parse(el.targeting_value)]
                  : el.targeting_value,
              uniqueIds: [el.targeting_id],
            });
          }
        });

        const usableTargetingSet = newTargetingSet.sort(
          (a: any, b: any) => a.targeting_set_rank - b.targeting_set_rank
        );

        usableTargetingSet.forEach((el: any) => {
          const param = params.find(
            (param: IFeatureSetParamDatum) => param.value === el.targeting_id
          );
          if (param) {
            const onChange = param?.param?.onChange;
            if (onChange !== undefined) {
              if (el.uniqueIds[0] === 21) {
                onChange(param.names[0], el.targeting_value[0]);
              } else {
                onChange(el.targeting_value[0]);
              }
            } else if (el.uniqueIds[0] === 1) {
              param.param.setTimeFrame(el.targeting_value[0]);
              param.param.setDateRange(el.targeting_value[1]);
            }
          }
        });
        setFeatureSetDataForMessagePrev(tempFeatureSetDataForMessage);
        setFeatureSetDataForMessage(tempFeatureSetDataForMessage);
        setCaregiverTargeting(
          usableTargetingSet.map((el: any) => el.targeting_id) ?? []
        );
        let targetingIds = usableTargetingSet.map((el: any) => el.targeting_id);
        const newElements = targetingDDElements.filter(
          (target: any) =>
            !targetingIds.includes(target.value) && target.is_caregiver
        );

        const isTargetingId5 = targetingIds.includes(5);
        const isTargetingId15 = targetingIds.includes(15);
        const isExist = isTargetingId15 ? 5 : 30;
        const disabledValue = isTargetingId5 ? 15 : isExist;

        const updatedElements = newElements.map((opt: any) =>
          opt.value === disabledValue ? { ...opt, disabled: true } : opt
        );

        targetableOptionsRef.current = [...updatedElements];
      } else if (selectedAdditionalOpts) {
        let newOption = targetingDDElements.filter(
          (target: any) => target.is_caregiver
        );
        targetableOptionsRef.current = [...newOption];
      }
    }else{
      let newOption = targetingDDElements.filter(
        (target: any) => target.is_caregiver
      );
      targetableOptionsRef.current = [...newOption];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      targetableOptionsRef.current.length === 0 &&
      Object.keys(updatedDataSet).length === 0
    ) {
      let newOption = targetingDDElements.filter(
        (target: any) => target.is_caregiver
      );
      targetableOptionsRef.current = [...newOption];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (caregiverTargeting.length !== 0) {
      let newOption = targetingDDElements.filter(
        (target: any) =>
          !caregiverTargeting.includes(target.value) && target.is_caregiver
      );
      const isTargetingId5 = caregiverTargeting.includes(5);
      const isTargetingId15 = caregiverTargeting.includes(15);
      const isExist = isTargetingId15 ? 5 : 30;
      const enableValue = isTargetingId5 ? 15 : isExist;
      const updatedOptionsWithEnabled = newOption.map((opt: any) =>
        opt.value === enableValue ? { ...opt, disabled: true } : opt
      );
      targetableOptionsRef.current = [...updatedOptionsWithEnabled];
    }
  }, []);

  const validationForNonArray = (tId: number, data: any) => {
    if (caregiverTargeting.includes(tId)) {
      updateIisFeatureSetsFilled(featureSetNumber, tId, !data ? 1 : 2);
      if (!data) {
        setIsSaveInitiated(false);
      }
      return;
    }
    updateIisFeatureSetsFilled(featureSetNumber, tId, 0);
  };

  const validationForArray = (tId: number, data: any) => {
    if (caregiverTargeting.includes(tId)) {
      updateIisFeatureSetsFilled(featureSetNumber, tId, !data.length ? 1 : 2);
      if (!data.length) {
        setIsSaveInitiated(false);
      }
      return;
    }
    updateIisFeatureSetsFilled(featureSetNumber, tId, 0);
  };

  const performValidations = () => {
    validationForNonArray(21, caregiverFeatureSet.caregiverGender);
    validationForArray(5, caregiverFeatureSet.selectedGeographyStates);
    validationForArray(15, caregiverFeatureSet.selectedGeographyDmas);
  };

  useEffect(() => {
    updateIsEnableSaveForEachSet(featureSetNumber, checkSaveEnabled());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caregiverTargeting, caregiverFeatureSet]);

  useEffect(() => {
    performValidations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFeatureSetUpdates = (key: string, data: any) => {
    if (data) {
      if (Array.isArray(data)) {
        updateCaregiverFeatureSet(data, key, true);
      } else {
        updateCaregiverFeatureSet(data, key, false);
      }
    }
  };

  const handleSetDelete = (
    setIds: [number, keyof ICareGiverFeatureSetBaseData]
  ) => {
    setIfDataModified(true);
    const newTargetingElemArray = caregiverTargeting.filter(
      (el) => setIds[0] !== el
    );
    if (caregiverTargeting.includes(setIds[0])) {
      setDeletedIds([setIds[0]]);
    }
    setCaregiverTargeting(newTargetingElemArray);
    updateCaregiverFeatureSet(baseValues[setIds[1]], setIds[1], false);
    const matchingTarget = targetingDDElements.find(
      (tar: any) => tar.value === setIds[0]
    );
    let disableValue: number | null = null;

    if (setIds[0] === 5) {
      disableValue = 15;
    } else if (setIds[0] === 15) {
      disableValue = 5;
    }

    const updatedOptionsWithEnabled = [...targetableOptionsRef.current].map(
      (opt: any) =>
        opt.value === disableValue ? { ...opt, disabled: false } : opt
    );

    if (matchingTarget) {
      targetableOptionsRef.current = [
        ...[...updatedOptionsWithEnabled, matchingTarget].sort(
          (a: any, b: any) => a.value - b.value
        ),
      ];
    }
  };

  const getParams = () => {
    const parmas: IFeatureSetParamDatum[] = [
      {
        value: 21,
        values: [caregiverFeatureSet.caregiverGender],
        names: ['caregiverGender'],
        base: [baseValues.caregiverGender],
        param: {
          setId: 21,
          featureSetNumber,
          label: 'Caregiver Gender',
          is_feature: true,
          options: genderDDElements,
          placeholder: 'Select Caregiver Gender',
          isSearchable: false,
          isDisabled: disabled,
          onChange: (key: string, data: any) => {
            setIfDataModified(true);
            handleFeatureSetUpdates('caregiverGender', data.value ?? data);
          },
          otherProps: { defaultValue: caregiverFeatureSet.caregiverGender },
          handleSetDelete,
        },
      },
      {
        value: 22,
        values: [caregiverFeatureSet.caregiverAge],
        names: ['caregiverAge'],
        base: [baseValues.caregiverAge],
        param: {
          setId: 22,
          featureSetNumber,
          label: 'Caregiver Age',
          is_feature: true,
          defaultValue: caregiverFeatureSet.caregiverAge?.length
            ? caregiverFeatureSet.caregiverAge
            : baseValues.caregiverAge,
          onChange: (data: any) => {
            setIfDataModified(true);
            handleFeatureSetUpdates('caregiverAge', data);
          },
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 5,
        values: [caregiverFeatureSet.selectedGeographyStates],
        names: ['selectedGeographyStates'],
        base: [baseValues.selectedGeographyStates],
        param: {
          setId: 5,
          featureSetNumber,
          label: 'State',
          is_feature: true,
          options: stateDDElements,
          placeholder: 'Select State',
          isSearchable: true,
          onChange: (data: any) => {
            setIfDataModified(true);
            handleFeatureSetUpdates('selectedGeographyStates', data);
          },
          handleSetDelete,
          otherProperties: {
            defaultValue: caregiverFeatureSet.selectedGeographyStates,
          },
          isDisabled: disabled,
        },
      },
      {
        value: 15,
        values: [caregiverFeatureSet.selectedGeographyDmas],
        names: ['selectedGeographyDmas'],
        base: [baseValues.selectedGeographyDmas],
        param: {
          setId: 15,
          label: 'DMA',
          is_feature: true,
          isSearchable: true,
          onChange: (data: any) => {
            setIfDataModified(true);
            handleFeatureSetUpdates('selectedGeographyDmas', data);
          },
          handleSetDelete,
          value: caregiverFeatureSet.selectedGeographyDmas,
          featureSetNumber,
          isDisabled: disabled,
        },
      },
    ];
    return parmas;
  };

  const getFormattedData = (message?: string) => {
    const tempData: any = {
        ...caregiverFeatureSet,
      },
      allUpdatingFeatureSetIds = updatedDataSet?.feature_sets?.map(
        (el: any) => el.feature_set_id
      );
    let allUpdatingTargetingIds: any = {};
    updatedDataSet?.feature_sets?.forEach((el: any) => {
      if (el.feature_set_id === featureSetNumber) {
        el.targeting.forEach(
          (elem: any) =>
            (allUpdatingTargetingIds = {
              ...allUpdatingTargetingIds,
              [elem.targeting_id]: elem.targeting_set_id,
            })
        );
      }
    });
    let newFeatureSetData: any = {
        feature_set_id: allUpdatingFeatureSetIds?.includes(featureSetNumber)
          ? featureSetNumber
          : 0,
        feature_set_summary: '',
        feature_set_rank: 1,
        condition_id: 5,
        is_caregiver: true,
        is_global: false,
      },
      targeting = [],
      duals: any = {
        dateRange: 17,
        selectedInsuranceProviders: 16,
      };

    for (const key in caregiverFeatureSet) {
      if (
        !(
          !tempData[key] ||
          (Array.isArray(tempData[key]) && tempData[key].length < 1)
        )
      ) {
        const params = getParams(),
          targetingId = params.find((el: IFeatureSetParamDatum) =>
            el.names.includes(key as any)
          )?.value,
          usableTargetingId = Object.keys(duals).includes(key)
            ? duals[key]
            : targetingId,
          usableTargetingSetId = Object.keys(allUpdatingTargetingIds).includes(
            `${usableTargetingId}`
          )
            ? allUpdatingTargetingIds[`${usableTargetingId}`]
            : 0;

        targeting.push({
          targeting_set_id: usableTargetingSetId,
          targeting_id: usableTargetingId,
          targeting_value: tempData[key],
          targeting_set_rank:
            caregiverTargeting.indexOf(targetingId as number) + 1,
        });
      }
    }

    if (isSaveInitiated && Object.keys(newFeatureSetData)?.length) {
      updateSavingDataSet(featureSetNumber, {
        ...newFeatureSetData,
        targeting,
      });
    }
  };

  useEffect(() => {
    if (isSaveInitiated) {
      const isEnableValues = Object.values(isEnableSaveForEachSet);
      performValidations();
      if (isEnableValues.includes(1)) {
        setIsSaveInitiated(false);
        return;
      }
      if (caregiverTargeting?.length) {
        getFormattedData();
      } else {
        updateSavingDataSet(featureSetNumber, {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveInitiated]);

  useEffect(() => {
    if (deletedIds?.length) {
      setDeletedIds(null);
      const params = getParams(),
        selectedParam: IFeatureSetParamDatum[] = params.filter((el: any) =>
          deletedIds?.includes(el.value)
        ),
        tempFeatureSetDataForMessage = { ...featureSetDataForMessage },
        dependentTargets = [6, 7, 8];

      selectedParam?.length &&
        selectedParam.forEach((eachParam: IFeatureSetParamDatum) => {
          if (dependentTargets.includes(eachParam.value)) {
            updateIsDependentTargetingSelectedForEachSet(
              featureSetNumber,
              false
            );
          }
        });
      setFeatureSetDataForMessage({ ...tempFeatureSetDataForMessage });
    }
    if (caregiverTargeting?.length) {
      if (
        caregiverTargeting.includes(22) &&
        !caregiverFeatureSet.caregiverAge?.length
      ) {
        handleFeatureSetUpdates('caregiverAge', baseValues.caregiverAge);
      }

      const params = getParams(),
        updatedElem: IFeatureSetElement[] = [],
        dependentTargets = [6, 7, 8];

      updateIsDependentTargetingSelectedForEachSet(
        featureSetNumber,
        Boolean(caregiverTargeting.find((el) => dependentTargets.includes(el)))
      );

      caregiverTargeting.forEach((el: number | string, index: number) => {
        updatedElem.push({
          setId: el as number,
          orderId: index,
          element: featureSetList
            .find((elem) => elem.value === (el as number))
            ?.component(
              params.find((elem) => elem.value === (el as number))?.param
            ),
        });
      });
      setFeatureSetBody(updatedElem);
    } else {
      setFeatureSetBody([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caregiverTargeting]);

  useEffect(() => {
    if (caregiverTargeting.length > 0) {
      const updatedElem: IFeatureSetElement[] = [],
        params = getParams();

      caregiverTargeting.forEach((el: number | string, index: number) => {
        updatedElem.push({
          setId: el as number,
          orderId: index,
          element: featureSetList
            .find((elem) => elem.value === (el as number))
            ?.component(
              params.find((elem: any) => elem.value === (el as number))?.param
            ),
        });
      });

      setFeatureSetBody(updatedElem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caregiverTargeting]);

  return (
    <>
      <div className="audienceDefinitionElem">
        <div className="audienceDefinitionleft">
          <p>Caregivers Profile</p>
        </div>

        <div className="audienceDefinitionRight">
          {caregiverTargeting.length > 0 && (
            <div>
              {featureSetBody.map((el: IFeatureSetElement) => (
                <Fragment key={`${el.orderId}_${el.setId}`}>
                  {el.element}
                </Fragment>
              ))}
            </div>
          )}
          {!disabled && targetableOptionsRef.current.length > 0 && (
            <Select
              style={{ width: 190 }}
              options={targetableOptionsRef.current}
              placeholder="Choose"
              onChange={(data: number) => handleTargetableOptions(data)}
              value={null}
            />
          )}
        </div>
      </div>
      <div className="intermediateContent">Who Are Caring For...</div>
    </>
  );
};

export default CareGiverFeatureSet;

