/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { Select } from 'antd';
import {
  IEachDropdownElementsType,
  IEnableSaveState,
  ISaveAudienceActivationState,
  activationKeyType,
} from '../../../models/interfaces';
import { useCheckSavingEnabilityStore } from '../../../stores';
import { DownOutlined, WarningOutlined } from '@ant-design/icons';
import { useSaveAudienceActivationStore } from '../../../stores/saveAndUpdateData.store';

const Single: FC<{
  options: IEachDropdownElementsType[];
  placeholder: string;
  isSearchable?: boolean;
  allowClear?: boolean;
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  onChange: React.Dispatch<React.SetStateAction<number | null>>;
  props?: any;
  targetingSetNumber?: number;
  featureSetNumber?: number;
  onClear?: Function;
  activationElemKey?: activationKeyType;
}> = ({
  options,
  placeholder,
  isSearchable = false,
  allowClear = true,
  componentSpecificClasses,
  isDisabled = false,
  onChange,
  props = {},
  targetingSetNumber,
  featureSetNumber,
  onClear = () => {},
  activationElemKey,
}) => {
  const [ifShowError, setIfShowError] = useState<boolean>(false);

  const { isFeatureSetsFilled, updateIisFeatureSetsFilled } =
      useCheckSavingEnabilityStore((state: IEnableSaveState) => state),
    { ifAllMandatoryFieldsPopulated, updateIfAllMandatoryFieldsPopulated } =
      useSaveAudienceActivationStore(
        (state: ISaveAudienceActivationState) => state
      );
  const filterOption = (input: string, option?: IEachDropdownElementsType) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onFocus = () => {
    if (ifShowError) {
      featureSetNumber &&
        targetingSetNumber &&
        updateIisFeatureSetsFilled(featureSetNumber, targetingSetNumber, 0);
      setIfShowError(false)

      activationElemKey &&
        updateIfAllMandatoryFieldsPopulated({ [activationElemKey]: 0 });
    }
  };

  useEffect(() => {
    setIfShowError(
      Boolean(
        featureSetNumber !== undefined &&
          targetingSetNumber !== undefined &&
          isFeatureSetsFilled[`${featureSetNumber}`][
            `${targetingSetNumber}`
          ] === 1
      )
    );
  }, [isFeatureSetsFilled]);

  useEffect(() => {
    setIfShowError(
      Boolean(
        activationElemKey &&
          ifAllMandatoryFieldsPopulated[activationElemKey] === 1
      )
    );
  }, [ifAllMandatoryFieldsPopulated]);

  return (
    <div className="eachCommonElementWrapper">
      <Select
        showSearch={isSearchable}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={onChange}
        onClear={onClear}
        filterOption={filterOption}
        options={options}
        allowClear={allowClear}
        className={`customAntSelect choosePixelSelect${
          componentSpecificClasses?.length
            ? ' ' + componentSpecificClasses.join(' ')
            : ''
        }`}
        disabled={isDisabled}
        onFocus={onFocus}
        status={ifShowError ? 'error' : ''}
        suffixIcon={
          ifShowError ? (
            <>
              <WarningOutlined className="validationErrorIcon" />
              <DownOutlined className="defaultArrowIcon" />
            </>
          ) : (
            <DownOutlined className="defaultArrowIcon" />
          )
        }
        {...props}
      />
    </div>
  );
};

export default Single;
