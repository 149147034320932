import { Dispatch, FC, SetStateAction, useState, useMemo } from 'react';
import Modal from 'antd/es/modal/Modal';
import { Button, Input, Radio, RadioChangeEvent, InputNumber } from 'antd';
import { CommonElementLabel, Multi } from '../../../sharedComponent';
import {
  useMasterCategoryElementsStore,
  useMasterPartnerListElementsStore,
} from '../../../../stores/masterData.store';
import {
  IMasterCategoryDDState,
  IMasterPartnerListDDState,
} from '../../../../models/interfaces';

interface AudienceModalProps {
  partner: string | null;
  modalToOpen: boolean;
  estimatedSize: string;
  activate: () => void;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  setPartner: Dispatch<SetStateAction<string | null>>;
  checkConfirmation: () => void;
  setEstimated_Size: Dispatch<SetStateAction<string>>;
  selectedAudienceType: number | null;
}

const AudienceDestinationModal: FC<AudienceModalProps> = ({
  modalToOpen,
  setEstimated_Size,
  estimatedSize,
  partner,
  checkConfirmation,
  setPartner,
  activate,
  setStep,
  step,
  selectedAudienceType,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [category, setCategory] = useState<number[]>([]);

  const { categoryElements } = useMasterCategoryElementsStore(
    (state: IMasterCategoryDDState) => state
  ),{ partnerListDDElements } = useMasterPartnerListElementsStore(
    (state: IMasterPartnerListDDState) => state
  );

  const filteredPartners = useMemo(() => {
    const query = searchValue.toLowerCase();

    const selectedCategoryLabels =
      category.length > 0
        ? category
            .map(
              (catId) =>
                categoryElements.find((cat) => cat.value === catId)?.label
            )
            .filter(Boolean)
        : [];

    return partnerListDDElements.filter((item: any) => {
      const nameMatches = item.partner_name.toLowerCase().includes(query);
      const categoryMatches =
        selectedCategoryLabels.length === 0 ||
        selectedCategoryLabels.includes(item.category_name);

      const audienceTypeMatches =
        (selectedAudienceType === 2 && item.hcp_active === true) ||
        (selectedAudienceType === 1 && item.dtc_active === true)
      return nameMatches && categoryMatches && audienceTypeMatches;
    });
  }, [
    searchValue,
    category,
    partnerListDDElements,
    categoryElements,
    selectedAudienceType,
  ]);

  const handleModalClose = () => {
    checkConfirmation();
  };

  const handleActivateClick = () => {
    if (step === 1) {
      setStep(2);
    } else {
      activate();
    }
  };

  const renderPartnerCard = (partnerData: any) => (
    <div className="partnerBlock">
      <div>
        <div className="partnerImg">
          <img
            src={`${process.env.REACT_APP_PROFILE_IMAGE_URL}/${process.env.REACT_APP_S3_FOLDER_NAME_PARTNER}/${partnerData.partner_logo}`}
            alt={partnerData.partner_name}
          />
        </div>
        <div className="partnernameAndStatus">
          <h4>{partnerData.partner_name}</h4>
          <p>{partnerData.sub_category_name}</p>
        </div>
      </div>
      <div className="categoryName">{partnerData.category_name}</div>
    </div>
  );

  const renderStepOne = () => (
    <>
      <div className="modalHeader">
        <h3>New Activation</h3>
        <p>Step #1: Choose the publishing partner...</p>
      </div>
      <div className="searchPartnerWrap">
        <Input
          value={searchValue}
          placeholder="Search Partners..."
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <div>
          <CommonElementLabel label="Jump to Category:" />
          <Multi
            allowClear={false}
            onChange={(data: any) => setCategory(data)}
            placeholder="Please select"
            options={categoryElements}
            value={category}
          />
        </div>
      </div>
      <Radio.Group
        onChange={(e: RadioChangeEvent) => setPartner(e.target.value)}
        value={partner}
        className="partnerDataSelectWrap"
      >
        {filteredPartners.map((partnerItem: any) => (
          <Radio key={partnerItem.id} value={partnerItem.key}>
            {renderPartnerCard(partnerItem)}
          </Radio>
        ))}
      </Radio.Group>
    </>
  );

  const renderStepTwo = () => {
    const selectedPartnerData = filteredPartners.find(
      (p: any) => p.key === partner
    );
    if (!selectedPartnerData) return null;

    return (
      <div>
        <div className="modalHeader">
          <h3>New Activation</h3>
          <p>Step #2: Configure the Activation</p>
          <p>Settings</p>
        </div>

        <p className="editPartner">
          Partner (<button onClick={() => setStep(1)}>edit</button>)
        </p>
        <div className="sizeEstimation">
          <Radio.Group value={partner} className="partnerDataSelectWrap">
            <Radio value={selectedPartnerData.key} checked={true}>
              {renderPartnerCard(selectedPartnerData)}
            </Radio>
          </Radio.Group>
          {selectedAudienceType !== 2 && (
            <div>
              <CommonElementLabel label="Audience Size Override: " />
              <div className="audienceSizeInput">
                <InputNumber
                  value={estimatedSize}
                  placeholder="Enter override size"
                  formatter={(value) =>
                    value
                      ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      : ''
                  }
                  min="0"
                  controls={false}
                  parser={(value) => (value ?? '0').replace(/,/g, '')}
                  onChange={(val) => setEstimated_Size(val ?? '')}
                />
                <p className="helpTextAudienceSize">
                  Specify a custom audience size. If left blank, the recommended
                  size will be used.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  // Modal Footer
  const renderModalFooter = () => (
    <>
      {step === 1 && partner && (
        <div className="currentlySelectedPartner">
          Currently Selected Partner:{' '}
          {filteredPartners.find((p: any) => p.key === partner)?.label}
        </div>
      )}
      <div className="modalCustomFooterBtnWrap">
        <Button className="noBGBtn" size="large" onClick={handleModalClose}>
          Cancel
        </Button>
        <Button
          className="primaryBtn"
          size="large"
          disabled={step === 1 && !partner}
          onClick={handleActivateClick}
        >
          {step === 1 ? 'Continue' : 'Activate'}
        </Button>
      </div>
    </>
  );

  return (
    <Modal
      centered
      open={modalToOpen}
      onCancel={handleModalClose}
      className="commonModal audienceDestinationModalWrap"
      width={1020}
      height={700}
      footer={renderModalFooter()}
    >
      <div className="audienceDestinationModal modalGenericContent">
        <div className="modalGenericTextContent">
          {step === 1 ? renderStepOne() : renderStepTwo()}
        </div>
      </div>
    </Modal>
  );
};

export default AudienceDestinationModal;
