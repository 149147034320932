/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  ModalComponent,
  PageHeader,
} from '../../../../components/sharedComponent';
import DefaultFeatureSet from './DefaultFeatureSet';
import {
  useCheckIfDataModifiedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useLoaderStore,
  useMasterAudienceTypeElementsStore,
} from '../../../../stores';
import {
  IAudienceSaveMethods,
  IAudienceSetUpState,
  IAudienceState,
  ICaregiverFeatureState,
  ICheckIfDataModifiedState,
  IEachDropdownElementsType,
  IEnableSaveState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IGlobalFeatureState,
  IHcpFeatureState,
  ILoaderState,
  IMasterAdvertiserDDState,
  IMasterAgencyDDState,
  IMasterAudienceTypeDDState,
  IMasterBrandDDState,
  IMasterPixelDDState,
  IMasterTargetingDDState,
} from '../../../../models/interfaces';
import { Button, Dropdown, MenuProps } from 'antd';
import TargetableFeatureSetup from './TargetableFeatureSetup';
import {
  useAudienceSaveMethods,
  useAudienceSetUp,
  useAudienceSets,
  useCareGiverFeatureSet,
  useGlobalFeatureSet,
  useHcpFeatureSet,
} from '../../../../stores/audienceSets.store';
import {
  useRedirection,
  useNotificationMessage,
  usePostApiInterceptor,
} from '../../../../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useMasterAdvertiserElementStore,
  useMasterAgencyElementStore,
  useMasterBrandElementStore,
  useMasterPixelElementsStore,
  useMasterTargetingElementsStore,
} from '../../../../stores/masterData.store';
import { OrganisationModal } from '../../AccountSettings/AccountSettingsComponent';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import { ReviewAudience } from './ReviewAudience';
import EstimateAlert from './EstimateAlert';
import { ActivateAudience } from './ActivateAudience';
import { DownOutlined } from '@ant-design/icons';

type SavingOptionType = {
  key: '1' | '2' | '3' | '4';
  label: string;
  messagecontent?: string;
};

export const savingOptions: SavingOptionType[] = [
  {
    key: '1',
    label: 'Save',
    messagecontent: 'Audience is saved',
  },
  {
    key: '2',
    label: 'Save and Exit',
    messagecontent: 'Audience is saved',
  },
  {
    key: '3',
    label: 'Build Audience Insights',
  },
];

export const defineOptions = {
  define: [
    { key: '1', label: 'Save', messagecontent: 'Audience is saved' },
    { key: '2', label: 'Save and Exit', messagecontent: 'Audience is saved' },
    { key: '3', label: 'Build Audience Insights' },
  ],
  estimate: [
    { key: '1', label: 'Save', messagecontent: 'Audience is saved' },
    { key: '2', label: 'Save and Exit', messagecontent: 'Audience is saved' },
    { key: '3', label: 'View Audience Insights' },
    { key: '4', label: 'Activate' },
  ],
};

export const reviewOptions = {
  estimate: [
    { key: '1', label: 'Edit Audience Definition' },
    { key: '2', label: 'Activate' },
  ],
  activate: [{ key: '1', label: 'Activate' }],
};

export const activateOptions = {
  estimate: [
    { key: '1', label: 'Edit Audience Definition' },
    { key: '2', label: 'View Audience Insights' },
  ],
  activate: [
    { key: '1', label: 'View Audience Definition' },
    { key: '2', label: 'View Audience Insights' },
  ],
};

type TOrganizationBody = {
  organization_type: string | null;
  id: number;
  name: string;
};

type TOrganization = 'agency' | 'brand' | 'advertiser';

const AudienceSetupFieldsWrap = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedAudienceType, setSelectedAudienceType] = useState<
      number | null
    >(null),
    [modalToOpenForCancel, setModalToOpenForCancel] = useState<boolean>(false),
    [selectedAdditionalOpts, setSelectedAdditionalOpts] =
      useState<boolean>(false),
    [deletingSetId, setDeletingSetId] = useState<number[] | null>(null),
    [prevSetId, setPrevSetId] = useState<number>(0),
    [selectedStatus, setSelectedStatus] = useState<number | null>(1),
    [audienceId, setAudienceId] = useState<number>(0),
    [focusOnTitle, setFocusOnTitle] = useState<0 | 1 | 2>(0),
    [hideEstimation, setHideEstimation] = useState(false),
    [organizationType, setOrganizationType] = useState<TOrganization>('agency'),
    [openOrganization, setOpenOrganization] = useState<boolean>(false),
    [fieldVal, setFieldVal] = useState<string>(''),
    [organizationMounted, setOrganizationMounted] = useState<boolean>(false),
    [organizationBody, setOrganizationBody] =
      useState<TOrganizationBody | null>(null),
    [pixelData, setPixelData] = useState<any>([]),
    [organizationId, setOrganizationId] = useState<number>(0),
    [pixelFieldData, setPixelFieldData] = useState<any>(''),
    [pixelName, setPixelName] = useState<string | null>(null),
    [pixelId, setPixelId] = useState<number>(0),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false });

  const { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { agencyDDElements, updateAgencyDDElements } = useMasterAgencyElementStore(
      (state: IMasterAgencyDDState) => state
    ),
    { brandDDElements, updateBrandDDElements } = useMasterBrandElementStore(
      (state: IMasterBrandDDState) => state
    ),
    { advertiserDDElements, updateAdvertiserDDElements } =
      useMasterAdvertiserElementStore(
        (state: IMasterAdvertiserDDState) => state
      ),
    { ifDataModified, setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    { pixelDDElements } = useMasterPixelElementsStore(
      (state: IMasterPixelDDState) => state
    ),
    { loaderState } = useLoaderStore((state: ILoaderState) => state),
    {
      advertiser,
      setAdvertiser,
      audiencePageNumber,
      setAudiencePageNumber,
      audienceTitle,
      audienceBrand,
      audienceAgency,
      setAudienceAgency,
      setAudienceBrand,
      setAudienceTitle,
    } = useAudienceSetUp((state: IAudienceSetUpState) => state),
    { setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    {
      selectedSavingOpt,
      setSelectedSavingOpt,
      setDefineFeatureData,
      setAudienceSummary,
      setDefineFeatureSet,
      setDefineFeatureCount,
      setFirstFeatureNumber,
    } = useAudienceSets((state: IAudienceState) => state),
    { updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { setCaregiverFeatureSet, setCaregiverTargeting } = useCareGiverFeatureSet(
      (state: ICaregiverFeatureState) => state
    ),
    { setGlobalFeatureSet, setGlobalTargeting } = useGlobalFeatureSet(
      (state: IGlobalFeatureState) => state
    ),
    { setHcpFeatureSet, setHcpTargeting } = useHcpFeatureSet(
      (state: IHcpFeatureState) => state
    ),
    { setTargetingDDElementsForFs } = useMasterTargetingElementsStore(
      (state: IMasterTargetingDDState) => state
    ),
    { setIisFeatureSetsFilled } = useCheckSavingEnabilityStore(
      (state: IEnableSaveState) => state
    );
  const { redirectAudienceListing } = useRedirection();

  const { context, destroyMessage } = useNotificationMessage(messageObj);

  useEffect(() => {
    return () => {
      setAudienceId(0);
      setSelectedAudienceType(null);
      setAudienceTitle('');
      setSelectedStatus(1);
      setSelectedAdditionalOpts(false);
      setDefineFeatureCount(0);
      setDefineFeatureData([]);
      setDefineFeatureSet({});
      setAudienceAgency(null);
      setFirstFeatureNumber(0);
      setAudienceBrand(null);
      setAdvertiser(null);
      setPrevSetId(0);
      setAudiencePageNumber(1);
      setHcpTargeting([]);
      setHcpFeatureSet({
        selectedProviderSpecialty: [],
        customNPIList: [],
        selectedProviderTaxonomy: [],
      });
      setCaregiverTargeting([]);
      setCaregiverFeatureSet({
        caregiverGender: null,
        caregiverAge: [],
        selectedGeographyDmas: [],
        selectedGeographyStates: [],
      });
      setGlobalTargeting([]);
      setSelectedSavingOpt('');
      setGlobalFeatureSet({
        timeFrame: null,
        dateRange: null,
        patientGender: null,
        selectedAge: [],
        selectedRaceAndEthnicity: [],
        selectedGeographyDmas: [],
        selectedGeographyStates: [],
        language: [],
      });
      setPixelData([]);
      setPixelFieldData([]);
      setDeletingSetId(null);
      setTargetingDDElementsForFs([]);
      setModalToOpenForCancel(false);
    };
  }, []);

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration: 5,
    });
  };

  useEffect(() => {
    if (pixelDDElements.length > 0) {
      const hasNoneDefault = pixelDDElements.some(
        (item: IEachDropdownElementsType) => item.value === 1000
      );

      if (!hasNoneDefault) {
        setPixelData([
          ...pixelDDElements,
          {
            value: 1000,
            label: 'None (Default)',
            order_id: 1000,
          },
        ]);
      } else {
        setPixelData(pixelDDElements);
      }
    }
  }, [pixelDDElements]);

  useEffect(() => {
    if (pixelName && pixelData.length > 0) {
      const filteredPixel = pixelData?.filter(
        (pix: any) => pix.label === pixelName
      );
      let temp = {
        label: filteredPixel[0]?.label ?? '',
        value: filteredPixel[0]?.label ?? '',
      };
      setPixelId(filteredPixel[0].value);
      setPixelFieldData(temp);
    }
  }, [pixelName, pixelData]);

  useEffect(() => {
    return () => {
      setAudienceTitle('');
      setSelectedAudienceType(null);
      setIisFeatureSetsFilled({});
      setAudienceAgency(null);
      setAudienceBrand(null);
      setAdvertiser(null);
      setDefineFeatureData([]);
    };
  }, []);

  const onSavingMenuClick: MenuProps['onClick'] = (e) => {
    setIsSaveInitiated(false);
    destroyMessage('save');
    setMessageObj({ isShowing: false });

    switch (audiencePageNumber) {
      case 1:
        handlePageOne(e);
        break;
      case 2:
        handlePageTwo(e);
        break;
      case 3:
        handlePageThree(e);
        break;
    }
  };

  const handlePageOne = (e: any) => {
    setSelectedSavingOpt(e.key);
    setIsSaveInitiated(true);
  };

  const handlePageTwo = (e: any) => {
    if (updatedDataSet.activation_list.length === 0) {
      setAudiencePageNumber(e.key === '1' ? 1 : 3);
    } else {
      setAudiencePageNumber(3);
    }
  };

  const handlePageThree = (e: any) => {
    if (updatedDataSet.activation_list.length === 0) {
      setAudiencePageNumber(e.key === '1' ? 1 : 2);
    } else {
      if (e.key === '1') {
        navigate(`/view-audience/${updatedDataSet.audience_id}`);
      }
      setAudiencePageNumber(2);
    }
  };

  useEffect(() => {
    if (selectedAudienceType === 2) {
      setSelectedAdditionalOpts(false);
    }
  }, [selectedAudienceType]);

  usePostApiInterceptor(
    organizationMounted,
    organizationBody,
    RoutesEnum.SAVE_ACCOUNT_ORGANIZATION,
    (data: any, error: any) => {
      if (data && !error) {
        updateErrorMessage(
          'success',
          `${
            organizationType.charAt(0).toUpperCase() + organizationType.slice(1)
          } is saved`
        );
        if (organizationType === 'advertiser') {
          updateAdvertiserDDElements(organizationBody?.name ?? '', data.org_id);
          setAdvertiser(data.org_id);
        } else if (organizationType === 'agency') {
          updateAgencyDDElements(organizationBody?.name ?? '', data.org_id);
          setAudienceAgency(data.org_id);
        } else {
          updateBrandDDElements(organizationBody?.name ?? '', data.org_id);
          setAudienceBrand(data.org_id);
        }
      } else {
        updateErrorMessage(
          'error',
          data.msg
            ? `${data.msg}`
            : `Error occurred while saving ${
                organizationType.charAt(0).toUpperCase() +
                organizationType.slice(1)
              }.`
        );
      }
      setOrganizationBody(null);
      setOrganizationMounted(false);
    }
  );

  useEffect(() => {
    if (ifUpdated) {
      const {
        title,
        audience_type_id,
        additional_option_id,
        advertiser_id: advertiser,
        agency_id: audience_agency,
        brand_id: audience_brand,
        pixel_name,
        feature_sets,
      } = updatedDataSet;
      setAudienceSummary(
        feature_sets.length ? feature_sets[0].feature_set_summary : ''
      );
      setPixelName(pixel_name);
      setAdvertiser(advertiser);
      setAudienceAgency(audience_agency);
      setAudienceBrand(audience_brand);
      setAudienceTitle(title);
      setSelectedAudienceType(audience_type_id);
      setSelectedAdditionalOpts(additional_option_id);
    }
  }, [ifUpdated]);

  const handleModalCancel = () => {
    setAudienceTitle('');
    setAudienceAgency(null);
    setAudienceBrand(null);
    setAdvertiser(null);
    redirectAudienceListing();
    setSelectedAudienceType(null);
    setDefineFeatureData(null);
    setAudiencePageNumber(1);
  };

  const handleCancel = () => {
    if (ifDataModified) {
      setModalToOpenForCancel(true);
    } else {
      setAudienceTitle('');
      setAudienceAgency(null);
      setAudienceBrand(null);
      setAdvertiser(null);
      redirectAudienceListing();
      setSelectedAudienceType(null);
      setDefineFeatureData([]);
    }
  };

  const handleSaveOrganization = (value: string) => {
    setOrganizationBody({
      organization_type: organizationType,
      id: organizationId,
      name: value,
    });
    setOrganizationMounted(true);
  };

  const getItems = () => {
    switch (audiencePageNumber) {
      case 1:
        return getDefineOptions();
      case 2:
        return getReviewOptions();
      default:
        return getActivateOptions();
    }
  };

  const getDefineOptions = () => {
    return updatedDataSet?.estimated_info
      ? defineOptions.estimate
      : defineOptions.define;
  };

  const getReviewOptions = () => {
    return updatedDataSet?.activation_list?.length === 0
      ? reviewOptions.estimate
      : reviewOptions.activate;
  };

  const getActivateOptions = () => {
    return updatedDataSet.activation_list.length === 0
      ? activateOptions.estimate
      : activateOptions.activate;
  };

  const onChange = (value: any) => {
    setIfDataModified(true);
    if (value === 'None (Default)') {
      setPixelFieldData(`${value}`);
    } else {
      const filteredPixel: IEachDropdownElementsType[] = pixelDDElements.filter(
        (pix: any) => pix.value === value
      );
      setPixelFieldData(`${value}`);
      setPixelId(filteredPixel[0].order_id);
      setPixelName(filteredPixel[0].label);
    }
  };

  return (
    <>
      <div className="audienceCommonWrap">
        {context}
        {!loaderState && (
          <>
            {audiencePageNumber === 1 && (
              <>
                <PageHeader
                  title={
                    id ? (
                      <>
                        Update:{' '}
                        <span className="lessOpacityText">{audienceTitle}</span>
                      </>
                    ) : (
                      'Create Audience'
                    )
                  }
                />
                <div>
                  <DefaultFeatureSet
                    defaultTitle={audienceTitle}
                    setTitle={setAudienceTitle}
                    audienceType={selectedAudienceType}
                    setAudienceType={(value: any) =>
                      setSelectedAudienceType(value)
                    }
                    advertiser={advertiser}
                    setAdvertiser={setAdvertiser}
                    defaultAgency={audienceAgency}
                    setAgency={setAudienceAgency}
                    defaultBrand={audienceBrand}
                    agencyDDElements={agencyDDElements}
                    brandDDElements={brandDDElements}
                    advertiserDDElements={advertiserDDElements}
                    setBrand={setAudienceBrand}
                    selectedAdditionalOpts={selectedAdditionalOpts}
                    setSelectedAdditionalOpts={setSelectedAdditionalOpts}
                    audienceTypeDDElements={audienceTypeDDElements}
                    focusOnTitle={focusOnTitle}
                    pixelData={pixelData}
                    pixelFieldData={pixelFieldData}
                    handlePixelChange={onChange}
                    setOrganisationType={setOrganizationType}
                    openOrganisation={setOpenOrganization}
                    disabled={updatedDataSet.audience_id ?? false}
                  />
                </div>
                <div className="activationRightPanel">
                  <div className="targetableFeatureAndAudienceInsight">
                    <TargetableFeatureSetup
                      setMessageObj={setMessageObj}
                      setFocusOnTitle={setFocusOnTitle}
                      pixelId={pixelId}
                      setPrevSetId={setPrevSetId}
                      prevSetId={prevSetId}
                      audienceId={audienceId}
                      setAudienceId={setAudienceId}
                      deletingSetId={deletingSetId}
                      setDeletingSetId={setDeletingSetId}
                      selectedStatus={selectedStatus}
                      setSelectedStatus={setSelectedStatus}
                      selectedAudienceType={selectedAudienceType}
                      selectedAdditionalOpts={selectedAdditionalOpts}
                      setSelectedAudienceType={setSelectedAudienceType}
                      setModalToOpenForCancel={setModalToOpenForCancel}
                      setSelectedAdditionalOpts={setSelectedAdditionalOpts}
                    />
                  </div>
                  {/* <AudienceInsightsWrap /> */}
                </div>
                {!updatedDataSet.estimated_info &&
                  updatedDataSet.audience_id &&
                  !hideEstimation && (
                    <EstimateAlert
                      className="audienceAlertInfo"
                      msgBody={
                        <p>
                          Your audience is saved but estimation is required to
                          continue. Click{' '}
                          <strong>‘Build Audience Insights’</strong> from the
                          Audience Actions menu when you’re ready to finalize.
                        </p>
                      }
                      setHideEstimateMsg={setHideEstimation}
                    />
                  )}
              </>
            )}
            {audiencePageNumber === 2 && <ReviewAudience />}
            {audiencePageNumber === 3 && <ActivateAudience />}
          </>
        )}
        <OrganisationModal
          type={organizationType}
          isOpen={openOrganization}
          onClose={() => setOpenOrganization(false)}
          onSave={handleSaveOrganization}
          value={fieldVal}
          setVal={setFieldVal}
          setId={setOrganizationId}
        />
      </div>
      <div className="newFeatureSetBtn">
        <div className="editAudienceNewFeatureSetBtn">
          <ModalComponent
            modalHeader={'Cancel ' + (id ? 'Update' : 'Create') + ' Audience'}
            modalToOpen={modalToOpenForCancel}
            setModalToOpen={setModalToOpenForCancel}
            deleteSetParams={{
              name: 'Cancel ' + (id ? 'Update' : 'Create') + ' Audience',
              handelOk: handleModalCancel,
              message:
                'You’re about to lose any unsaved changes. Do you want to continue?',
              okText: 'Yes',
              cancelText: 'No',
            }}
          />
          {audiencePageNumber === 1 && (
            <Button
              type="text"
              size="large"
              onClick={handleCancel}
              className="noBGBtn"
            >
              Cancel
            </Button>
          )}
          <Dropdown
            className="dropdownSaveButton"
            trigger={['click']}
            disabled={
              !(
                selectedAudienceType &&
                audienceTitle &&
                audienceAgency &&
                audienceBrand &&
                advertiser
              )
            }
            menu={{
              items: getItems(),
              defaultSelectedKeys: [selectedSavingOpt],
              onClick: onSavingMenuClick,
            }}
          >
            <Button type="primary" size="large">
              Audience Actions
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default AudienceSetupFieldsWrap;
