import { useEffect, useState } from 'react';
import { usePostApiInterceptor } from '../../../hooks';
import { LeftPanel } from '../LeftNavPanel';
import { PixelSetupFieldWrap } from './PixelSetupComponents';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import { useParams } from 'react-router-dom';

const PixelSetupPage = () => {
  const { id } = useParams();

  document.title = (id ? 'Update Pixel ' : 'Create Pixel ') + '- BranchLab';

  const [body, setBody] = useState<{ pixel_id: number } | null>(null),
  [isMounted, setIsMounted] = useState<boolean>(false),
  [pixelData, setPixelData] = useState<any>(null),
  [isLoading, setIsLoading] = useState<boolean>(true),
  [isError, setIsError] = useState<boolean>(false);

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.GET_PIXEL_DATA,
    (data: any, error: any) => {
      setIsMounted(false);
      if (error || !data || (data && !data.pixel_info)) {
        setIsError(true);
        return;
      }
      setBody(null)
      setPixelData(data);
      setIsLoading(false);
    }
  );
  
  useEffect(() => {
    if (id) {
      isError && setIsError(false);
      setBody({ pixel_id: parseInt(id) });
      setIsMounted(true);
    }else{
      setIsLoading(false)
    }
  }, [id]);

  return (
    <div className="audienceSetupPage">
      <LeftPanel defaultSelectedKeys={['5']} />
      {!isLoading && <PixelSetupFieldWrap pixel_data={pixelData}/>}
    </div>
  );
};

export default PixelSetupPage;
