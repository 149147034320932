import React, { FC, PropsWithChildren, useState } from 'react';
import { logoLogin } from '../../../assets/images';
import { Button, Form, Input } from 'antd';
import { CheckOutlined, CloseOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {
  useAuth,
  useNotificationMessage,
  usePostApiInterceptor,
} from '../../../hooks';
import { cognitoConfig } from '../../../awsConfig/awsConfig';
import { Amplify, Auth } from 'aws-amplify';
import { RoutesEnum } from '../../../models/enums/apiRoutes';

Amplify.configure(cognitoConfig);

interface SubmitButtonProps {
  label?: string;
  isLoading: boolean;
  type: 'generate' | 'save';
}

const SubmitButton: FC<PropsWithChildren<SubmitButtonProps>> = ({
  label = 'submit',
  isLoading,
  type = 'generate',
}) => {
  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={isLoading}
      className={`primaryBtn ${type === 'save' ? 'loginBtn' : 'generateBtn'}${
        !isLoading ? '' : ' loginButtonDisable'
      }`}
      loading={isLoading}
    >
      {label}
    </Button>
  );
};

const ForgotPassword = () => {
  document.title = 'Forgot Password - BranchLab';
  const [isLoadingLogin, setIsLoadingLogin] = useState<boolean>(false),
    [isGenerating, setIsGenerating] = useState(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [stage, setStage] = useState<1 | 2>(1),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [body, setBody] = useState<{ email: string }>({ email: '' });

  const { context, destroyMessage } = useNotificationMessage(messageObj);
  const minLengthRegex = /^.{6,}$/;
  const maxLengthRegex = /^.{1,127}$/;
  const isNotEmailAddress = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const mixedCaseAndNumberRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/;
  const specialCharacterRegex = /(?=.*\W)/;

  const { logout } = useAuth();

  const updateErrorMessage = (
    type: string,
    messagecontent: string,
    duration: number = 5
  ) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration,
    });
  };

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.EMAIL_AVAILABILITY_CHECK,
    (data: any, error: any) => {
      setIsMounted(false);
      if (!error && data?.msg_id === 101) {
        Auth.forgotPassword(body.email)
          .then((data) => {
            console.info('Verification code has been sent.');

            updateErrorMessage(
              'success',
              'Verification code has been sent to your email address.',
              3
            );

            setStage(2);
          })
          .catch((err) => {
            handleError(err.message);
          });
      } else {
        handleError(error);
      }
    },
    false
  );

  const handleError = (errorMessage: string) => {
    console.error(
      'Error occurred while generating verification code.',
      errorMessage
    );

    updateErrorMessage(
      'error',
      'Error occurred while generating verification code.'
    );

    setIsGenerating(false);
  };

  const renderEyeIcon = (visible: boolean) =>
    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />;

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const generateCode = () => {
    setIsGenerating(true);
    destroyMessage('save');
    setMessageObj({ isShowing: false });
    if (values.email) {
      setBody({ email: values.email });
      setIsMounted(true);
    }
  };

  const savePassword = () => {
    destroyMessage('save');
    setMessageObj({ isShowing: false });
    setIsLoadingLogin(true);

    const { code, password } = values;
    if (code && password) {
      Auth.forgotPasswordSubmit(body.email, code, password)
        .then((data) => {
          console.info('Password has been changed successfully.');

          setTimeout(() => {
            logout();
          }, 4000);

          updateErrorMessage(
            'success',
            'Password has been changed successfully and will be redirecting login page shortly.',
            5
          );
        })
        .catch((err) => {
          console.error('Error occurred while updating password.', err.message);

          updateErrorMessage(
            'error',
            'Error occurred while updating password.'
          );

          setIsLoadingLogin(false);
        });
    }
  };

  return (
    <div className="loginWrap">
      <div className="loginContentWrap">
        {context}
        <div className="loginContent">
          <h1>
            <img src={logoLogin} alt="" />
          </h1>
          {stage === 2 && (
            <Form
              form={form}
              labelCol={{ span: 24 }}
              name="bl_login"
              autoComplete="off"
              requiredMark={false}
              onFinish={savePassword}
            >
              <Form.Item
                label="Verification Code"
                name="code"
                validateDebounce={1000}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Verification Code is required',
                  },
                ]}
              >
                <Input.OTP />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                validateDebounce={1000}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  className="loginPageField"
                  disabled={!values?.code}
                  iconRender={renderEyeIcon}
                />
              </Form.Item>
              <div className="rules">
                <p>Password requirements:</p>
                <ul>
                  <li>
                    {values?.password &&
                    minLengthRegex.test(values?.password) ? (
                      <CheckOutlined className='tick'/>
                    ) : (
                      <CloseOutlined className='cross'/>
                    )}{' '}
                    must be a least 6 characters
                  </li>
                  <li>
                    {values?.password &&
                    maxLengthRegex.test(values?.password) ? (
                      <CheckOutlined className='tick'/>
                    ) : (
                      <CloseOutlined className='cross'/>
                    )}{' '}
                    must be fewer than 128 characters
                  </li>
                  <li>
                    {values?.password &&
                    !isNotEmailAddress.test(values?.password) ? (
                      <CheckOutlined className='tick'/>
                    ) : (
                      <CloseOutlined className='cross'/>
                    )}{' '}
                    must be different from email address
                  </li>
                  <li>
                    {mixedCaseAndNumberRegex.test(values?.password) ? (
                      <CheckOutlined className='tick'/>
                    ) : (
                      <CloseOutlined className='cross'/>
                    )}{' '}
                    must include letters in mixed case and numbers
                  </li>
                  <li>
                    {specialCharacterRegex.test(values?.password) ? (
                      <CheckOutlined className='tick'/>
                    ) : (
                      <CloseOutlined className='cross'/>
                    )}{' '}
                    must include a character that is not a letter or number
                  </li>
                </ul>
              </div>
              <Form.Item>
                <SubmitButton
                  label="Reset"
                  isLoading={isLoadingLogin}
                  type="save"
                />
              </Form.Item>
            </Form>
          )}
          {stage === 1 && (
            <Form
              form={form}
              labelCol={{ span: 24 }}
              name="bl_login"
              autoComplete="off"
              requiredMark={false}
              onFinish={generateCode}
            >
              <Form.Item
                label="Email"
                name="email"
                validateDebounce={1000}
                validateFirst
                rules={[
                  {
                    required: true,
                    message: 'Email is required',
                  },
                  {
                    type: 'email',
                    message: 'Enter a valid email',
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder="Email"
                  className="loginPageField"
                  disabled={isGenerating}
                  autoFocus
                />
              </Form.Item>
              <Form.Item>
                <SubmitButton
                  label="Reset Password"
                  isLoading={isGenerating}
                  type="generate"
                />
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
