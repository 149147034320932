import { FC } from 'react';
import { emptyData } from '../../../assets/images';

const CustomEmptyTableComponent: FC<{ message: string }> = ({
  message,
}) => {
  return (
    <span className="emptyTableDataWrap">
      <img src={emptyData} alt="" />
      {
        <p>
            {message}
        </p>
      }
    </span>
  );
};

export default CustomEmptyTableComponent;
