import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import {
  useAuth,
  useNotificationMessage,
  usePostApiInterceptor,
} from '../../../hooks';
import { logoLogin } from '../../../assets/images';
import { IUserDetailsBody } from '../../../models/interfaces';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { cognitoConfig } from '../../../awsConfig/awsConfig';
import { Amplify, Auth } from 'aws-amplify';

Amplify.configure(cognitoConfig);

interface SubmitButtonProps {
  label?: string;
  isLoading: boolean;
}

const SubmitButton: FC<PropsWithChildren<SubmitButtonProps>> = ({
  label = 'submit',
  isLoading,
}) => {
  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={isLoading}
      className={`primaryBtn loginBtn${
        !isLoading ? '' : ' loginButtonDisable'
      }`}
      loading={isLoading}
    >
      {label}
    </Button>
  );
};

const LoginPage: FC<{}> = () => {
  document.title = 'Login - BranchLab';

  const messageKey = 'LoginErrorMessage';
  const navigate = useNavigate();

  const [body, setBody] = useState<IUserDetailsBody | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [isLoadingLogin, setIsLoadingLogin] = useState<boolean>(false);

  const { login } = useAuth();

  const { context, destroyMessage } = useNotificationMessage(messageObj);
  const { isError } = usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.USER_INFORMATION,
    (data: any, error: any) => {
      setIsMounted(false);
      if (data && !error) {
        const fetchUserInfo = data;
        if (fetchUserInfo?.length) {
          login(fetchUserInfo[0]);
        } else {
          updateErrorMessage();
        }
      } else if (!error || !data) {
        updateErrorMessage();
      }
      setIsLoadingLogin(false);
    }
  );

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  function updateErrorMessage() {
    console.log("reaching")
    setMessageObj({
      key: messageKey,
      isShowing: true,
      type: 'error',
      messagecontent: 'Incorrect credentials, please try again',
    });
  }

  const tryLogin = async () => {
    setIsLoadingLogin(true);

    setMessageObj({ isShowing: false });
    destroyMessage(messageKey);

    const tempBody: IUserDetailsBody = { ...values };

    setBody(tempBody);

    try {
      const user = await Auth.signIn({ ...values, username: values.email });

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(user, values.password);
      }

      setIsMounted(true);
    } catch (error) {
      updateErrorMessage();
      console.error('Error signing in', error);
      setIsLoadingLogin(false);
    }
  };

  const renderEyeIcon = (visible: boolean) =>
    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />;

  useEffect(() => {
    if (isError) {
      updateErrorMessage();
    }
  }, [isError]);

  return (
    <div className="loginWrap">
      <div className="loginContentWrap">
        <div className="loginContent">
          {context}
          <h1>
            <img src={logoLogin} alt="" />
          </h1>
          <Form
            form={form}
            labelCol={{ span: 24 }}
            name="bl_login"
            autoComplete="off"
            requiredMark={false}
            onFinish={tryLogin}
            className='loginForm'
          >
            <Form.Item
              label="Email"
              name="email"
              validateDebounce={1000}
              validateFirst
              rules={[
                {
                  required: true,
                  message: 'Email is required',
                },
                {
                  type: 'email',
                  message: 'Enter a valid email',
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Email"
                className="loginPageField"
                disabled={isLoadingLogin}
                autoFocus
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              validateDebounce={1000}
              rules={[
                {
                  required: true,
                  message: 'Password is required',
                },
              ]}
            >
              <Input.Password
                placeholder="Password"
                className="loginPageField"
                disabled={isLoadingLogin}
                iconRender={renderEyeIcon}
              />
            </Form.Item>
            <span
                className='forgotPass'
                onClick={() => navigate('/forgot-password')}
              >
                Forgot Password?
              </span>

            <Form.Item>
              
              <SubmitButton label="Login" isLoading={isLoadingLogin} />
            </Form.Item>
          </Form>
          <p className="noAcc">
            Don’t have an account?{' '}
            <Link
              to="https://branchlab.com/contact/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us
            </Link>
          </p>
        </div>
        <ul className="externalLinks">
          <li>
            <Link
              to="https://branchlab.atlassian.net/wiki/spaces/platform/overview"
              target="_blank"
              rel="noopener noreferrer"
            >
              Help
            </Link>
          </li>
          <li>
            <Link
              to="https://branchlab.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </Link>
          </li>
          <li>
            <Link
              to="https://branchlab.com/terms-conditions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LoginPage;
