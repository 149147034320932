import React, { FC } from 'react'
import closeBlack from '../../../../assets/images/close-black.svg';

const EstimateAlert: FC<{
    setHideEstimateMsg: React.Dispatch<React.SetStateAction<boolean>>;
    msgBody: React.ReactNode;
    className: string;
}> = ({
    setHideEstimateMsg,
    msgBody,
    className
}) => {
        return (
            <div className={`audienceAlert ${className}`}>
                <span className="leftIcon"></span>
                {msgBody}
                <button
                    className="closeCTA"
                    onClick={() => setHideEstimateMsg(true)}
                >
                    <img src={closeBlack} alt="" />
                </button>
            </div>
        )
    }

export default EstimateAlert;