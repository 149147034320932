import { FC, Fragment, useEffect, useState } from 'react';
import {
  IAudienceSaveMethods,
  IAudienceState,
  ICheckIfDataModifiedState,
  ICustomNPIList,
  IEnableSaveState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IFeatureSetElement,
  IFeatureSetParamDatum,
  IHcpFeatureSetBaseData,
  IHcpFeatureState,
  IMasterProviderSpecialtyDDState,
  IMasterProviderTaxonomyDDState,
  IMasterTargetingDDState,
} from '../../../../models/interfaces';
import { Select } from 'antd';
import {
  useAudienceSaveMethods,
  useAudienceSets,
  useHcpFeatureSet,
} from '../../../../stores/audienceSets.store';
import {
  useCheckIfDataModifiedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useMasterProviderSpecialtyElementsStore,
  useMasterProviderTaxonomyElementStore,
  useMasterTargetingElementsStore,
} from '../../../../stores';
import { featureSetList } from './featureSetList';

const baseValues: IHcpFeatureSetBaseData = {
  selectedProviderSpecialty: [],
  customNPIList: [],
  selectedProviderTaxonomy: [],
};

const HcpFeatureSet: FC<{
  featureSetNumber: number;
  featureSetOrderNumber: number;
  disabled?: boolean;
}> = ({ featureSetNumber, featureSetOrderNumber, disabled }) => {
  const { isCancelled } = useAudienceSets((state: IAudienceState) => state),
    {
      hcpFeatureSet,
      updateHcpFeatureSet,
      hcpTargeting,
      setHcpTargeting,
      updateHcpTargeting,
    } = useHcpFeatureSet((state: IHcpFeatureState) => state),
    { isSaveInitiated, setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    { setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { updateSavingDataSet, updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    {
      isEnableSaveForEachSet,
      updateIsEnableSaveForEachSet,
      updateIisFeatureSetsFilled,
    } = useCheckSavingEnabilityStore((state: IEnableSaveState) => state),
    { providerTaxonomyDDElements } = useMasterProviderTaxonomyElementStore(
      (state: IMasterProviderTaxonomyDDState) => state
    ),
    { providerSpecialtyDDElements } = useMasterProviderSpecialtyElementsStore(
      (state: IMasterProviderSpecialtyDDState) => state
    ),
    { targetingDDElements } = useMasterTargetingElementsStore(
      (state: IMasterTargetingDDState) => state
    );

  const [featureSetBody, setFeatureSetBody] = useState<IFeatureSetElement[]>(
      []
    ),
    [ifUpdatedCompleted, setIfUpdatedCompleted] = useState<0 | 1 | 2>(0),
    [uuid, setUuid] = useState<any>(new Date().valueOf()),
    [targetableOptions, setTargetableOptions] = useState<any>([]);

  useEffect(() => {
    if (targetableOptions.length === 0 && hcpTargeting.length < 1) {
      let newOptions = targetingDDElements.filter(
        (tar) => tar.is_hpa && !tar.is_caregiver && !tar.is_global
      );
      setTargetableOptions(newOptions);
    } else if (hcpTargeting.length > 0) {
      let newOptions = targetingDDElements.filter(
        (tar) =>
          !hcpTargeting.includes(Number(tar.value)) &&
          tar.is_hpa &&
          !tar.is_caregiver &&
          !tar.is_global
      );
      setTargetableOptions(newOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCancelled) {
      setFeatureSetBody([]);
    }
  }, [isCancelled]);

  const handleTargetableOptions = (value: any) => {
    updateHcpTargeting(value);
    let newOptions = targetableOptions
      .filter((tar: any) => tar.value !== value)
      .map((tar: any) => ({
        ...tar,
        disabled: false,
      }));
    setTargetableOptions(newOptions);
  };

  const checkSaveEnabled = () => {
    switch (true) {
      case !hcpTargeting?.length:
        return 0;
      case hcpTargeting.includes(6) &&
        !hcpFeatureSet.selectedProviderSpecialty?.length:
      case hcpTargeting.includes(7) && !hcpFeatureSet.customNPIList?.length:
      case hcpTargeting.includes(20) &&
        !hcpFeatureSet?.selectedProviderTaxonomy?.length:
        return 1;
      default:
        return 2;
    }
  };

  useEffect(() => {
    updateIsEnableSaveForEachSet(featureSetNumber, checkSaveEnabled());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hcpTargeting, hcpFeatureSet]);

  const handleFeatureSetUpdates = (key: string, data: any) => {
    if (data) {
      updateHcpFeatureSet(data, key, true);
    }
  };

  const performValidations = () => {
    validationForArray(6, hcpFeatureSet.selectedProviderSpecialty);
    validationForArray(7, hcpFeatureSet.customNPIList);
    validationForArray(20, hcpFeatureSet.selectedProviderTaxonomy);
  };

  const validationForArray = (tId: number, data: any) => {
    if (hcpTargeting.includes(tId)) {
      updateIisFeatureSetsFilled(featureSetNumber, tId, !data?.length ? 1 : 2);
      if (!data.length) {
        setIsSaveInitiated(false);
      }
      return;
    }
    updateIisFeatureSetsFilled(featureSetNumber, tId, 0);
  };

  useEffect(() => {
    performValidations();
  }, []);

  const handleSetDelete = (setIds: [number, keyof IHcpFeatureSetBaseData]) => {
    setIfDataModified(true);
    const newTargetingElemArray = hcpTargeting.filter((el) => setIds[0] !== el);
    setHcpTargeting(newTargetingElemArray);
    updateHcpFeatureSet(baseValues[setIds[1]], setIds[1], false);
    let newTarget = targetingDDElements
      .filter(
        (tar: any) =>
          tar.value === setIds[0] && !tar.is_caregiver && !tar.is_global
      )
      .map((tar: any) => ({ ...tar, disabled: false }));
    setTargetableOptions([...targetableOptions, ...newTarget]);
  };

  const getParams = () => {
    const params: IFeatureSetParamDatum[] = [
      {
        value: 6,
        values: [hcpFeatureSet.selectedProviderSpecialty],
        names: ['selectedProviderSpecialty'],
        base: [baseValues.selectedProviderSpecialty],
        param: {
          setId: 6,
          featureSetNumber,
          label: 'Provider Speciality',
          is_feature: true,
          options: providerSpecialtyDDElements,
          placeholder: 'Select Provider Specialities',
          isSearchable: true,
          onChange: (data: any) => {
            setIfDataModified(true);
            handleFeatureSetUpdates('selectedProviderSpecialty', data);
          },
          handleSetDelete,
          otherProperties: {
            defaultValue: hcpFeatureSet.selectedProviderSpecialty,
          },
          isDisabled: disabled,
        },
      },
      {
        value: 7,
        values: [hcpFeatureSet.customNPIList],
        names: ['customNPIList'],
        base: [baseValues.customNPIList],
        param: {
          setId: 7,
          featureSetNumber,
          label: 'Custom NPI List',
          is_feature: true,
          uuid: uuid,
          customNPIList: hcpFeatureSet.customNPIList,
          setCustomNPIList: (data: any) => {
            handleFeatureSetUpdates('customNPIList', data);
          },
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 20,
        values: [hcpFeatureSet.selectedProviderTaxonomy],
        names: ['selectedProviderTaxonomy'],
        base: [baseValues.selectedProviderTaxonomy],
        param: {
          setId: 20,
          featureSetNumber,
          label: 'Provider Taxonomy',
          is_feature: true,
          options: providerTaxonomyDDElements,
          placeholder: 'Select Provider Taxonomy',
          isSearchable: true,
          onChange: (data: any) => {
            handleFeatureSetUpdates('selectedProviderTaxonomy', data);
            setIfDataModified(true);
          },
          handleSetDelete,
          otherProperties: {
            defaultValue: hcpFeatureSet.selectedProviderTaxonomy,
          },
          isDisabled: disabled,
        },
      },
    ];
    return params;
  };

  useEffect(() => {
    if (hcpTargeting?.length) {
      const updatedElem: IFeatureSetElement[] = [],
        params = getParams();
      hcpTargeting.forEach((el: number | string, index: number) => {
        updatedElem.push({
          setId: el as number,
          orderId: index,
          element: featureSetList
            .find((elem) => elem.value === (el as number))
            ?.component(
              params.find((elem) => elem.value === (el as number))?.param
            ),
        });
      });

      setFeatureSetBody(updatedElem);
    }
  }, [hcpTargeting, hcpFeatureSet]);

  // Custom NPI List
  useEffect(() => {
    if (
      (ifUpdatedCompleted === 0 || (ifUpdated && ifUpdatedCompleted === 2)) &&
      hcpTargeting.includes(7)
    ) {
      setIfDataModified(true);
      const usableData: ICustomNPIList[] = hcpFeatureSet.customNPIList.map(
        (el) => ({
          key: el.key,
          displayName: el.displayName,
          name: el.name,
          uuid: el.uuid,
        })
      );
      updateHcpFeatureSet(usableData, 'customNPIList', false);
    }
  }, [ifUpdated]);

  const getFormattedData = () => {
    const tempData: any = {
        ...hcpFeatureSet,
      },
      allUpdatingFeatureSetIds = updatedDataSet?.feature_sets?.map(
        (el: any) => el.feature_set_id
      );
    let allUpdatingTargetingIds: any = {};
    updatedDataSet?.feature_sets?.forEach((el: any) => {
      if (el.feature_set_id === featureSetNumber) {
        el.targeting.forEach(
          (elem: any) =>
            (allUpdatingTargetingIds = {
              ...allUpdatingTargetingIds,
              [elem.targeting_id]: elem.targeting_set_id,
            })
        );
      }
    });

    let newFeatureSetData: any = {
        feature_set_id: allUpdatingFeatureSetIds?.includes(featureSetNumber)
          ? featureSetNumber
          : 0,
        feature_set_rank: featureSetOrderNumber,
        condition_id: 5,
        is_global: false,
        is_hcp: true,
      },
      targeting = [];
    for (const key in hcpFeatureSet) {
      if (
        !(
          !tempData[key] ||
          (Array.isArray(tempData[key]) && tempData[key].length < 1)
        )
      ) {
        const params = getParams(),
          targetingId = params.find((el: IFeatureSetParamDatum) =>
            el.names.includes(key as any)
          )?.value,
          usableTargetingId = targetingId,
          usableTargetingSetId = Object.keys(allUpdatingTargetingIds).includes(
            `${usableTargetingId}`
          )
            ? allUpdatingTargetingIds[`${usableTargetingId}`]
            : 0;

        targeting.push({
          targeting_set_id: usableTargetingSetId,
          targeting_id: usableTargetingId,
          targeting_value: tempData[key],
          targeting_set_rank: hcpTargeting.indexOf(targetingId as number) + 1,
        });
      }
    }

    if (isSaveInitiated && Object.keys(newFeatureSetData)?.length) {
      updateSavingDataSet(featureSetNumber, {
        ...newFeatureSetData,
        targeting,
      });
    }
  };

  useEffect(() => {
    if (isSaveInitiated) {
      const isEnableValues = Object.values(isEnableSaveForEachSet);
      performValidations();
      if (isEnableValues.includes(1)) {
        setIsSaveInitiated(false);
        return;
      }
      if (hcpTargeting?.length) {
        getFormattedData();
      } else {
        updateSavingDataSet(featureSetNumber, {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveInitiated]);

  useEffect(() => {
    if (
      ifUpdated &&
      updatedDataSet?.feature_sets?.length &&
      hcpTargeting.length === 0
    ) {
      setIfUpdatedCompleted(1);
      const featureSet = updatedDataSet.feature_sets.find(
        (el: any) => el.feature_set_id === featureSetNumber && el.is_hcp
      );

      if (featureSet) {
        const params = getParams(),
          targetingSet = featureSet.targeting.sort(
            (a: any, b: any) => a.targeting_id - b.targeting_id
          );

        let newTargetingSet: any = [],
          targetId: any = [];
        // let tempFeatureSetDataForMessage = {};

        targetingSet.forEach((el: any) => {
          newTargetingSet.push({
            ...el,
            targeting_value:
              el.targeting_id !== 14
                ? [JSON.parse(el.targeting_value)]
                : el.targeting_value,
            uniqueIds: [el.targeting_id],
          });
          targetId.push(el.targeting_id);
        });

        const usableTargetingSet = newTargetingSet.sort(
          (a: any, b: any) => a.targeting_set_rank - b.targeting_set_rank
        );

        usableTargetingSet.forEach((el: any) => {
          if (el.targeting_id === 7) {
            setUuid(el.targeting_value[0][0].uuid);
          }
          const param = params.find(
            (param: IFeatureSetParamDatum) => param.value === el.targeting_id
          );
          if (param) {
            const onChange = param?.param?.onChange;
            if (onChange !== undefined) {
              onChange(el.targeting_value[0]);
            }
            if (el.uniqueIds[0] === 7) {
              param.param.setCustomNPIList(el.targeting_value[0]);
            }
          }
        });
        let newOptions = targetingDDElements.filter(
          (tar) =>
            !targetId.includes(tar.value) &&
            tar.is_hpa &&
            !tar.is_caregiver &&
            !tar.is_global
        );
        setTargetableOptions(newOptions);
        setHcpTargeting(usableTargetingSet.map((el: any) => el.targeting_id));
      }
    }
  }, []);

  return (
    <>
      <div className="audienceDefinitionElem">
        <div className="audienceDefinitionleft">
          <p>Healthcare Professional Attributes</p>
        </div>
        <div className="audienceDefinitionRight">
          {hcpTargeting.length > 0 && (
            <div>
              {featureSetBody.map((el: IFeatureSetElement) => (
                <Fragment key={`${el.orderId}_${el.setId}`}>
                  {el.element}
                </Fragment>
              ))}
            </div>
          )}

          {!disabled && targetableOptions.length > 0 && (
            <Select
              style={{ width: 190 }}
              options={targetableOptions}
              placeholder="Choose"
              onChange={(data: number) => handleTargetableOptions(data)}
              value={null}
            />
          )}
        </div>
      </div>
      <div className="intermediateContent">Who Are Treating...</div>
    </>
  );
};

export default HcpFeatureSet;
