import { FC } from 'react';
import { breadcrumbSeparator } from '../../../assets/images';
import { IAudienceSetUpState } from '../../../models/interfaces';
import { useAudienceSetUp } from '../../../stores/audienceSets.store';
import { useLocation } from 'react-router-dom';

interface BreadcrumbProps {
  items: any;
  activeIndex: number;
  disabled?: boolean;
}

const Breadcrumb: FC<BreadcrumbProps> = ({ items, activeIndex, disabled }) => {
  const { pathname } = useLocation();
  const { setAudiencePageNumber } = useAudienceSetUp(
      (state: IAudienceSetUpState) => state
    );

  const handleNavigate = (item: any) => {
    setAudiencePageNumber(item.key);
  };

  return (
    <div className="breadCrumbContainer">
      {items.map((item: any) => (
        <span key={item.key}>
          <span
            
            className="breadCrumbLink"
          >
            {item.key !== 1 && (
              <span className="breadCrumbSeparator">
                <img src={breadcrumbSeparator} alt="arrow" />
              </span>
            )}
            <button
              disabled={pathname.includes('/create-audience') || 
                (disabled && (item.key === 1))
              }
              onClick={() => handleNavigate(item)}
              style={{
                color:
                  item.key === activeIndex
                    ? 'var(--bl-header)'
                    : 'var(--bl-grey-2)',
              }}
            >
              {item.title}
            </button>
          </span>
        </span>
      ))}
    </div>
  );
};

export default Breadcrumb;
