import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeftPanel } from "../LeftNavPanel";
import { useFeatureSetDataCheckStore, useFeatureSetDataHandlingStore } from "../../../stores";
import { IAudienceSetUpState, IFeatureSetDataCheckState, IFeatureSetDataState } from "../../../models/interfaces";
import { usePostApiInterceptor } from "../../../hooks";
import { RoutesEnum } from "../../../models/enums/apiRoutes";
import { AudienceSetupFieldsWrap } from "../AudienceSetup/AudienceSetupComponents";
import { useAudienceSetUp } from "../../../stores/audienceSets.store";


const AudienceActivationPage: FC = () => {
  document.title = 'Audience Activation - BranchLab';

  const { audId } = useParams();

  const [body, setBody] = useState<{ audience_id: string } | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [isLoading, setIsLoading] = useState(true),
    [isReviewMounted, setIsReviewMounted] = useState(false),
    [isReviewBody, setIsReviewBody] = useState<{ audience_id: number } | null>(
      null
    ),
    // [activatedData, setActivatedData] = useState<any>([]),
    [isError, setIsError] = useState<boolean>(false);

  const { setIfUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { setAudiencePageNumber }= useAudienceSetUp((state: IAudienceSetUpState) => state),
    { updatedDataSet, setUpdatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    );

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.GET_FEATURE_SET_DATA,
    (data: any, error: any) => {
      setIsMounted(false);
      if (error || !data || (data && !data.audience_info)) {
        setIsError(true);
        setIsLoading(false);
        return;
      }
      setUpdatedDataSet(data.audience_info);
      setIsReviewMounted(true);
      setIsReviewBody({ audience_id: parseInt(audId ?? '1') });
      setIfUpdated(true);
    }
  );

  usePostApiInterceptor(
    isReviewMounted,
    isReviewBody,
    RoutesEnum.GET_ACTIVATION,
    (data: any, error: any) => {
      setIsReviewMounted(false);
      setIsReviewBody(null);
      if (error || !data) {
        setIsError(true);
        setIsLoading(false);
        return;
      }
      if (data.estimated_info) {
        updatedDataSet['estimated_info'] = data.estimated_info;
      }
      updatedDataSet['activation_list'] = data.activation_list ?? [];
      setUpdatedDataSet(updatedDataSet);
      setAudiencePageNumber(3)
      setIsLoading(false);
    }
  );

  useEffect(() => {
    if (audId) {
      setUpdatedDataSet({});
      isError && setIsError(false);
      setBody({ audience_id: audId });
      setIsMounted(true);
    } else {
      setIsLoading(false);
    }
  }, [audId]);
  

  return (
    <div className="audienceSetupPage audienceActivation">
      <LeftPanel defaultSelectedKeys={['3']} />
      {!isLoading && <AudienceSetupFieldsWrap />}
    </div>
  );
};

export default AudienceActivationPage;
