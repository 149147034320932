import React, { Fragment, useEffect, useState } from 'react';
import { LeftPanel } from '../LeftNavPanel';
import { PageHeader } from '../../sharedComponent';
import {
  Organisation,
  OrganisationModal,
} from '../AccountSettings/AccountSettingsComponent';
import { useNotificationMessage, usePostApiInterceptor } from '../../../hooks';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import {
  ILoaderState,
  Iadvertiser,
  Iagency,
  Ibrand,
} from '../../../models/interfaces';
import { useLoaderStore } from '../../../stores';

type TOrganizationBody = {
  organization_type: string | null;
  id: number;
  name: string;
};

const OrganizationSettings = () => {
  document.title = 'Organization - BranchLab';
  const { setIsMounted } = useLoaderStore((state: ILoaderState) => state);
  const [isSettingMounted, setIsSettingMounted] = useState<boolean>(false),
    [brand, setBrand] = useState<Ibrand[]>([]),
    [agency, setAgency] = useState<Iagency[]>([]),
    [body, setBody] = useState<any>({}),
    [isLoading, setIsLoading] = useState<boolean>(true),
    [advertiser, setAdvertiser] = useState<Iadvertiser[]>([]),
    [organizationType, setOrganizationType] = useState<string | null>(null),
    [openOrganization, setOpenOrganization] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [organizationId, setOrganizationId] = useState<number>(0),
    [organizationMounted, setOrganizationMounted] = useState<boolean>(false),
    [organizationBody, setOrganizationBody] =
      useState<TOrganizationBody | null>(null),
    [fieldVal, setFieldVal] = useState<string>('');

  const { context, destroyMessage } = useNotificationMessage(messageObj);

  useEffect(() => {
    setBody({});
    setIsLoading(true);
    setIsSettingMounted(true);
  }, []);

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration: 5,
    });
  };

  usePostApiInterceptor(
    isSettingMounted,
    body,
    RoutesEnum.GET_ACCOUNT_SETTINGS,
    (data: any, error: any) => {
      setIsSettingMounted(false);
      if (error || !data) {
        setIsLoading(false);
        return;
      }
      setAgency(data.agency_list ?? []);
      setAdvertiser(data.advertiser_list ?? []);
      setBrand(data.brand_list ?? []);
      setIsSettingMounted(false);
      setIsLoading(false);
    }
  );

  usePostApiInterceptor(
    organizationMounted,
    organizationBody,
    RoutesEnum.SAVE_ACCOUNT_ORGANIZATION,
    (data: any, error: any) => {
      setOrganizationBody(null);
      setOrganizationMounted(false);
      if (data && !error) {
        setBody({});
        setIsSettingMounted(true);
        setIsMounted(true);
        updateErrorMessage(
          'success',
          `${
            organizationType === 'advertiser'
              ? 'Advertiser'
              : organizationType === 'agency'
              ? 'Agency'
              : 'Brand'
          } is saved`
        );
      } else {
        updateErrorMessage(
          'error',
          data.msg
            ? `${data.msg}`
            : `Error occurred while saving ${
                organizationType === 'advertiser'
                  ? 'Advertiser'
                  : organizationType === 'agency'
                  ? 'Agency'
                  : 'Brand'
              }.`
        );
      }
      setFieldVal('')
    }
  );

  const handleMessage = () => {
    setMessageObj({
      key: 'save',
      isShowing: false,
      duration: 5,
    });
    destroyMessage('save');
  };

  const handleSaveOrganization = (value: string) => {
    setOrganizationBody({
      organization_type: organizationType,
      id: organizationId,
      name: value,
    });
    setOrganizationMounted(true);
  };

  return (
    <Fragment>
      {context}
      <LeftPanel defaultSelectedKeys={['7', '73']} />
      {!isLoading && (
        <div className="audienceCommonWrap">
          <PageHeader title="Organization" />
          <div>
            <Organisation
              brands={brand}
              agencies={agency}
              advertisers={advertiser}
              isLoading={isLoading}
              onOpen={() => setOpenOrganization(true)}
              setType={setOrganizationType}
              setField={setFieldVal}
              setId={setOrganizationId}
              destroyMessage={handleMessage}
            />
          </div>
          {openOrganization && (
            <OrganisationModal
              type={
                organizationType === 'agency'
                  ? 'agency'
                  : organizationType === 'brand'
                  ? 'brand'
                  : 'advertiser'
              }
              isOpen={openOrganization}
              onClose={() => setOpenOrganization(false)}
              onSave={handleSaveOrganization}
              value={fieldVal}
              setVal={setFieldVal}
              setId={setOrganizationId}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};

export default OrganizationSettings;
