/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState, Fragment } from 'react';
import DefinedFeatureSet from './DefinedFeatureSet';
import {
  Button,
  Collapse,
  GetProp,
  Modal,
  UploadFile,
  UploadProps,
} from 'antd';
import { CaretRightOutlined, PlusOutlined } from '@ant-design/icons';
import {
  useCheckDependentTargetingSelectedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useLoaderStore,
  useMasterTargetingElementsStore,
  useUserInformationStore,
} from '../../../../stores';
import {
  IAudienceSaveMethods,
  IAudienceSetUpState,
  IAudienceState,
  ICaregiverFeatureState,
  ICustomNPIList,
  IDependentTargetingSelectedState,
  IEachDropdownElementsType,
  IEnableSaveState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IFeatureSetElement,
  IGlobalFeatureState,
  IHcpFeatureState,
  ILoaderState,
  IMasterTargetingDDState,
  IPresignedURLRequest,
  IUserInformationState,
} from '../../../../models/interfaces';
import {
  useAudienceSaveMethods,
  useAudienceSetUp,
  useAudienceSets,
  useCareGiverFeatureSet,
  useGlobalFeatureSet,
  useHcpFeatureSet,
} from '../../../../stores/audienceSets.store';
import { useParams } from 'react-router-dom';
import {
  useNotificationMessage,
  usePostApiInterceptor,
  useRedirection,
} from '../../../../hooks';
import type { CollapseProps } from 'antd';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import GlobalFeatureSet from './GlobalFeatureSet';
import CareGiverFeatureSet from './CareGiverFeatureSet';
import HcpFeatureSet from './HcpFeatureSet';
import apiService from '../../../../services/Api.service';
import axios from 'axios';
import AdminOptions from './AdminOptions';
import {
  useMasterDmaElementsStore,
  useMasterLanguageElementStore,
  useMasterProviderSpecialtyElementsStore,
  useMasterProviderTaxonomyElementStore,
} from '../../../../stores/masterData.store';
import MessageFormatter from './MessageFormatter';

interface AdminOption {
  admin_option_id: number;
  is_search_for_treatments: boolean;
  drugs_count: number;
  is_distinct: boolean;
  updated_on?: string;
  is_updating?: boolean;
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
type UploadReqType = { url?: string; body?: any; isError: boolean };

function separateFeatureSetsByTargetingId(data: any): {
  withTargetingId: any;
  withoutTargetingId: any;
  withCaregiver: any;
  withHcp: any;
} {
  const withTargetingId: any = [];
  const withoutTargetingId: any = [];
  let withCaregiver: any = null;
  let withHcp: any = null;
  data.forEach((featureSet: any) => {
    if (featureSet?.is_global) {
      withTargetingId.push(featureSet);
    } else if (featureSet.is_caregiver) {
      withCaregiver = featureSet;
    } else if (featureSet.is_hcp) {
      withHcp = featureSet;
    } else {
      withoutTargetingId.push(featureSet);
    }
  });

  return { withTargetingId, withoutTargetingId, withCaregiver, withHcp };
}

const TargetableFeatureSetup: FC<{
  selectedAudienceType: number | null;
  selectedAdditionalOpts: boolean;
  pixelId: number;
  audienceId: number;
  selectedStatus: number | null;
  prevSetId: number;
  deletingSetId: number[] | null;
  setDeletingSetId: React.Dispatch<React.SetStateAction<number[] | null>>;
  setPrevSetId: React.Dispatch<React.SetStateAction<number>>;
  setSelectedStatus: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectedAdditionalOpts: React.Dispatch<React.SetStateAction<boolean>>;
  setModalToOpenForCancel: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAudienceType: React.Dispatch<React.SetStateAction<number | null>>;
  setFocusOnTitle: React.Dispatch<React.SetStateAction<0 | 1 | 2>>;
  setAudienceId: React.Dispatch<React.SetStateAction<number>>;
  setMessageObj: React.Dispatch<React.SetStateAction<any>>;
}> = ({
  setMessageObj,
  setFocusOnTitle,
  selectedAudienceType,
  selectedAdditionalOpts,
  pixelId,
  prevSetId,
  deletingSetId,
  setDeletingSetId,
  setPrevSetId,
  audienceId,
  setAudienceId,
  selectedStatus,

  setSelectedStatus,
  setSelectedAudienceType,
  setModalToOpenForCancel,
  setSelectedAdditionalOpts,
}) => {
  const { id } = useParams();
  const { ifUpdated, setIsInitiatedSaving, setIfUpdated } =
      useFeatureSetDataCheckStore((state: IFeatureSetDataCheckState) => state),
    {
      isEnableSaveForEachSet,
      setIsEnableSaveForEachSet,
      setIisFeatureSetsFilled,
      isFeatureSetsFilled,
    } = useCheckSavingEnabilityStore((state: IEnableSaveState) => state),
    {
      isDependentTargetingSelectedForEachSet,
      setIsDependentTargetingSelectedForEachSet,
    } = useCheckDependentTargetingSelectedStore(
      (state: IDependentTargetingSelectedState) => state
    ),
    {
      audienceTitle,
      audienceAgency,
      audienceBrand,
      advertiser,
      setAdvertiser,
      setAudienceAgency,
      setAudienceBrand,
      setAudienceTitle,
      setAudiencePageNumber,
    } = useAudienceSetUp((state: IAudienceSetUpState) => state),
    { isSaveInitiated, setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    {
      defineFeatureSet,
      setIsDataSaved,
      defineFeatureData,
      setDefineFeatureCount,
      selectedSavingOpt,
      isCancelled,
      setSelectedSavingOpt,
      setIsCancelled,
      setDefineFeatureData,
      firstFeatureNumber,
      setDefineFeatureSet,
      setFirstFeatureNumber,
      setFeatureSetDataForMessagePrev,
      defineFeatureCount,
    } = useAudienceSets((state: IAudienceState) => state),
    { updatedDataSet, savingDataSet, setUpdatedDataSet } =
      useFeatureSetDataHandlingStore((state: IFeatureSetDataState) => state),
    { userInformation } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { setShowBlankLoaderState } = useLoaderStore(
      (state: ILoaderState) => state
    ),
    {
      setCaregiverFeatureSet,
      setCaregiverTargeting,
      caregiverFeatureSet,
      caregiverTargeting,
    } = useCareGiverFeatureSet((state: ICaregiverFeatureState) => state),
    {
      setGlobalFeatureSet,
      setGlobalTargeting,
      globalFeatureSet,
      globalTargeting,
    } = useGlobalFeatureSet((state: IGlobalFeatureState) => state),
    { setHcpFeatureSet, setHcpTargeting, hcpTargeting, hcpFeatureSet } =
      useHcpFeatureSet((state: IHcpFeatureState) => state),
    { targetingDDElements, setTargetingDDElementsForFs } =
      useMasterTargetingElementsStore(
        (state: IMasterTargetingDDState) => state
      );

  const { resetAllStates, redirectAudienceListing } = useRedirection();

  const [body, setBody] = useState<any>(''),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [adminData, setAdminData] = useState<AdminOption>({
      admin_option_id: 0,
      is_search_for_treatments: false,
      drugs_count: 0,
      is_distinct: false,
    }),
    [globalMsg, setGlobalMsg] = useState<string>(''),
    [modalToOpen, setModalToOpen] = useState(false),
    [isSummary, setIsSummary] = useState<boolean>(false),
    [bodySummary, setBodySummary] = useState<any>(null),
    [notificationObj, setNotificationObj] = useState<any>({ isShowing: false }),
    [globalFeatureSets, setGlobalFeatureSets] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    }),
    [hcpFeaturesSet, setHcpFeaturesSet] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    }),
    [careGiverSet, setCareGiverSet] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    });

  const { context } = useNotificationMessage(notificationObj);

  useEffect(() => {
    if (isCancelled) {
      setDefineFeatureCount(0);
      setIsCancelled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancelled]);

  const featureSetBody = (feature_sets: IFeatureSetElement[]) => {
    const { withTargetingId, withoutTargetingId } =
      separateFeatureSetsByTargetingId(feature_sets);
    let newTargetingOptions: number[] = [];
    if (feature_sets.length > 0) {
      const newFeatureSets: IFeatureSetElement[] = [];
      let largestSetId = 0;
      if (withoutTargetingId.length > 0) {
        withoutTargetingId
          .sort((a: any, b: any) => a.feature_set_rank - b.feature_set_rank)
          .forEach((el: any, index: number) => {
            newTargetingOptions.push(
              el.targeting[0].targeting_id === 16
                ? 12
                : el.targeting[0].targeting_id
            );
            if (el.feature_set_id > largestSetId) {
              largestSetId = el.feature_set_id;
            }
            newFeatureSets.push({
              setId: el.feature_set_id,
              orderId: index + 1,
            });
          });
        setPrevSetId(largestSetId);
        setDefineFeatureCount(withoutTargetingId.length);
        setDefineFeatureData(
          createFeatureSet(newFeatureSets, withoutTargetingId.length)
        );
      } else if(defineFeatureCount < 1) {
        setFirstFeatureNumber(1);
        handleAddFeature();
      }
      if (withTargetingId.length) {
        setGlobalFeatureSets({
          setId: withTargetingId[0].feature_set_id,
          orderId: 1,
          element: createGlobalElem(withTargetingId[0].feature_set_id, 1),
        });
      } else if(withTargetingId.length === 0){
        setGlobalFeatureSets({
          setId: 0,
          orderId: 1,
          element: createGlobalElem(0, 0),
        });
      }
    } else {
      setGlobalFeatureSets({
        setId: 0,
        orderId: 1,
        element: createGlobalElem(0, 0),
      });
      if(defineFeatureCount < 1){
        setFirstFeatureNumber(1);
      handleAddFeature();
      }
    }
    let newOptions = targetingDDElements.filter(
      (tar) =>
        !newTargetingOptions.includes(Number(tar.value)) &&
        !tar.is_hpa &&
        !tar.is_caregiver &&
        !tar.is_global
    );
    setTargetingDDElementsForFs(newOptions);
  };

  const getItems: () => CollapseProps['items'] = () => [
    {
      key: '1',
      label: <div className="audienceDefinitionleft">Admin Options</div>,
      children: <AdminOptions data={adminData} setData={setAdminData} />,
    },
  ];

  useEffect(() => {
    if (ifUpdated && Object.keys(updatedDataSet).length !== 0) {
      setShowBlankLoaderState(false);
      const { audience_id, status_id, feature_sets, admin_options } =
        updatedDataSet;
      setAudienceId(audience_id);
      setSelectedStatus(status_id);
      if (admin_options) {
        setAdminData(admin_options);
      }
      if (updatedDataSet.feature_sets) {
        featureSetBody(feature_sets);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ifUpdated, updatedDataSet]);

  useEffect(() => {
    const handleEffect = async () => {
      if (body) {
        if (hcpTargeting.includes(7)) {
          await uploadNpiList();
        }
        setShowBlankLoaderState(true);
        setIsMounted(true);
        setNotificationObj({
          isShowing: true,
          type: 'success',
          messagecontent: 'Audience Saved',
        });
      }
    };

    handleEffect();
  }, [body]);

  const uploadNpiList = async () => {
    const uploadableNPIs = hcpFeatureSet.customNPIList.filter(
      (el: ICustomNPIList) => el.file
    );
    if (uploadableNPIs?.length) {
      const tempPresignedUrlReq: any = uploadableNPIs.map(
        (el: ICustomNPIList) => ({
          key: el.key,
          url: RoutesEnum.GET_PRESIGNED_URL,
          body: {
            bucket: process.env.REACT_APP_S3_BUCKET_NAME,
            key: `${process.env.REACT_APP_S3_FOLDER_NAME}/${el.uuid}/${el.name}`,
            expiration: 6000,
          },
          file: el.file as UploadFile,
        })
      );
      getPresignedURLs(tempPresignedUrlReq);
    }
  };

  const uploadAllFiles = (requests: UploadReqType[]) => {
    const promises = requests.map((el: UploadReqType) => {
      if (!el.isError && el.url && el.body) {
        return axios.post(el.url, el.body, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        return null;
      }
    });

    Promise.all(promises)
      .then((res) => console.log('uploading'))
      .catch((error: any) => {
        console.error(error.message);
      });
  };

  const getPresignedURLs = (requests: IPresignedURLRequest[]) => {
    const promises = requests.map((el: IPresignedURLRequest) =>
      apiService.post<any>(
        process.env.REACT_APP_BASE_URL + el.url,
        el.body,
        {},
        true
      )
    );
    Promise.all(promises)
      .then(
        (res) => {
          let tempUploadReq: UploadReqType[] = [];
          res.forEach((r, i) => {
            if (r) {
              const formData = new FormData();

              for (const key in r.presigned_url.fields) {
                formData.append(`${key}`, r.presigned_url.fields[key]);
              }
              formData.append(
                'file',
                hcpFeatureSet.customNPIList[i].file as FileType
              );

              tempUploadReq = [
                ...tempUploadReq,
                {
                  url: r.presigned_url.url,
                  body: formData,
                  isError: false,
                },
              ];
            } else {
              tempUploadReq = [...tempUploadReq, { isError: true }];
            }
          });
          uploadAllFiles(tempUploadReq);
        },
        (err) => {
          console.error(err.message);
        }
      )
      .catch((error: any) => {
        console.error(error.message);
      });
  };

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.SAVE_FEATURE_SET_DATA,
    (data: any, error: any) => {
      setBody('');
      setIsMounted(false);
      setIsInitiatedSaving(false);
      setNotificationObj({ isShowing: false });
      setIsSaveInitiated(false);
      setShowBlankLoaderState(false);
      if (data && !error) {
        setIsDataSaved(true);
        eventAfterSave(data.audience_id);
      } else {
        updateErrorMessage('error', 'Error occurred while saving Audience.');
      }
    }
  );

  usePostApiInterceptor(
    isSummary,
    bodySummary,
    RoutesEnum.GET_FEATURE_SET_SUMMARY,
    (data: any, error: any) => {
      console.log(data);
      // setAudienceSummary(data);
      setIsSummary(false);
      setBodySummary(null);
      if (data && !error) {
        // setFeatureSetDataForMessagePrev(featureSetDataForMessage);
      }
    }
  );

  const generateMessage = () => {
    if (Object.keys(globalMsg)?.length) {
      // setBodySummary({ globalMsg });
      // setIsSummary(true);
    }
  };

  useEffect(() => {
    if (Object.keys(globalMsg)?.length) {
      const handle = setTimeout(() => {
        generateMessage();
      }, Number(process.env.REACT_APP_MESSAGE_GENERATION_INTERVAL));

      return () => {
        clearTimeout(handle);
      };
    }
  }, [globalMsg]);

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration: 5,
    });
  };

  useEffect(() => {
    if (
      !id &&
      defineFeatureCount > 0 &&
      defineFeatureCount > defineFeatureData.length &&
      !defineFeatureData
    ) {
      setDefineFeatureData(createFeatureSet());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defineFeatureCount]);

  const createFeatureSet = (
    existingFeatureSets: IFeatureSetElement[] = [],
    targetFeatureCount: number = defineFeatureCount
  ): IFeatureSetElement[] => {
    const featureSets = existingFeatureSets.length
      ? existingFeatureSets
      : defineFeatureData;

    const newFeatureSets = featureSets
      .slice(0, targetFeatureCount)
      .map(({ setId, orderId }, index) => {
        if (index === 0 && !firstFeatureNumber) {
          setFirstFeatureNumber(setId);
        }
        return { setId, orderId, element: createNewElem(setId, orderId) };
      });

    if (targetFeatureCount > featureSets.length) {
      const newSetId = prevSetId + 1;
      setPrevSetId(newSetId);
      newFeatureSets.push({
        setId: newSetId,
        orderId: targetFeatureCount,
        element: createNewElem(newSetId, targetFeatureCount),
      });
    }

    return newFeatureSets;
  };

  useEffect(() => {
    if (deletingSetId?.length) {
      const tempIsEnableSaveForEachSet = { ...isEnableSaveForEachSet },
        tempIsDependentTargetingSelectedForEachSet = {
          ...isDependentTargetingSelectedForEachSet,
        };
      delete isFeatureSetsFilled[`${deletingSetId[0]}`];
      delete tempIsEnableSaveForEachSet[`${deletingSetId[0]}`];
      delete tempIsDependentTargetingSelectedForEachSet[`${deletingSetId[0]}`];
      setIsEnableSaveForEachSet(tempIsEnableSaveForEachSet);
      setIsDependentTargetingSelectedForEachSet(
        tempIsDependentTargetingSelectedForEachSet
      );
      setIisFeatureSetsFilled(isFeatureSetsFilled);
      delete defineFeatureSet[deletingSetId[0]];
      setDefineFeatureSet(defineFeatureSet);
      setDefineFeatureCount(defineFeatureCount - 1);
      const tempFeatureSets = defineFeatureData
        .filter((el: IFeatureSetElement) => !deletingSetId.includes(el.setId))
        .map((el: IFeatureSetElement, index: number) => {
          if (index === 0) setFirstFeatureNumber(el.setId);
          return {
            setId: el.setId,
            orderId: index + 1,
            element: createNewElem(el.setId, index + 1),
          };
        });

      setDefineFeatureData(tempFeatureSets);
      setDeletingSetId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletingSetId]);

  const resetAllLocalStates = () => {
    setAudienceId(0);
    setSelectedAudienceType(null);
    setAudienceTitle('');
    setSelectedStatus(1);
    setSelectedAdditionalOpts(false);
    setDefineFeatureCount(0);
    setGlobalMsg('');
    setDefineFeatureData([]);
    setDefineFeatureSet({});
    setAudienceAgency(null);
    setAudienceBrand(null);
    setAdvertiser(null);
    setPrevSetId(0);
    setAudiencePageNumber(1);
    setFirstFeatureNumber(0);
    setHcpTargeting([]);
    setHcpFeatureSet({
      selectedProviderSpecialty: [],
      customNPIList: [],
      selectedProviderTaxonomy: [],
    });
    setCaregiverTargeting([]);
    setCaregiverFeatureSet({
      caregiverGender: null,
      caregiverAge: [],
      selectedGeographyDmas: [],
      selectedGeographyStates: [],
    });
    setGlobalTargeting([]);
    setGlobalFeatureSet({
      timeFrame: null,
      dateRange: null,
      patientGender: null,
      selectedAge: [],
      selectedRaceAndEthnicity: [],
      selectedGeographyDmas: [],
      selectedGeographyStates: [],
      language: [],
    });
    setDeletingSetId(null);
    setTargetingDDElementsForFs([]);
    setModalToOpenForCancel(false);
  };

  useEffect(() => {
    const isEnableValues = Object.values(isEnableSaveForEachSet);
    if (
      isEnableValues?.length &&
      !isEnableValues.includes(1) &&
      isEnableValues.includes(2) &&
      isEnableValues.find((el) => el !== 0)
    ) {
      getOverAllData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savingDataSet]);

  useEffect(() => {
    const isEnableValues = Object.values(isEnableSaveForEachSet);
    if (
      !(defineFeatureData?.length && isEnableValues.find((el) => el !== 0)) &&
      !isEnableValues.includes(1) &&
      isSaveInitiated
    ) {
      getOverAllData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveInitiated]);

  // logic only for first time audience creation
  useEffect(() => {
    if (Object.keys(updatedDataSet).length === 0 && defineFeatureCount < 1) {
      setGlobalFeatureSets({
        setId: 0,
        orderId: 0,
        element: createGlobalElem(0, 0),
      });
      setFirstFeatureNumber(1);
      handleAddFeature();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(updatedDataSet).length === 0) {
      if (selectedAudienceType === 2) {
        setHcpFeaturesSet({
          setId: 98,
          orderId: 98,
          element: createHcpElem(98, 98),
        });
      } else {
        setHcpFeaturesSet({
          setId: 0,
          orderId: 0,
        });
        setHcpTargeting([]);
        setHcpFeatureSet({
          selectedProviderSpecialty: [],
          customNPIList: [],
          selectedProviderTaxonomy: [],
        });
      }
    }
  }, [selectedAudienceType]);

  useEffect(() => {
    if (Object.keys(updatedDataSet).length !== 0) {
      const { withHcp } = separateFeatureSetsByTargetingId(
        updatedDataSet.feature_sets
      );
      if (withHcp && selectedAudienceType === 2) {
        setHcpFeaturesSet({
          setId: withHcp.feature_set_id,
          orderId: 98,
          element: createHcpElem(withHcp.feature_set_id, 98),
        });
      } else if (!withHcp && selectedAudienceType === 2) {
        setHcpFeaturesSet({
          setId: 98,
          orderId: 98,
          element: createHcpElem(98, 98),
        });
      } else {
        setHcpFeaturesSet({
          setId: 0,
          orderId: 0,
        });
        setHcpTargeting([]);
        setHcpFeatureSet({
          selectedProviderSpecialty: [],
          customNPIList: [],
          selectedProviderTaxonomy: [],
        });
      }
    }
  }, [selectedAudienceType]);

  useEffect(() => {
    if (Object.keys(updatedDataSet).length === 0) {
      if (selectedAdditionalOpts) {
        setCareGiverSet({
          setId: 99,
          orderId: 99,
          element: createCareGiverElem(99, 99),
        });
      } else {
        setCareGiverSet({
          setId: 0,
          orderId: 0,
        });
        setCaregiverTargeting([]);
        setCaregiverFeatureSet({
          caregiverGender: null,
          caregiverAge: [],
          selectedGeographyDmas: [],
          selectedGeographyStates: [],
        });
      }
    }
  }, [selectedAdditionalOpts]);

  useEffect(() => {
    if (Object.keys(updatedDataSet).length !== 0) {
      const { withCaregiver } = separateFeatureSetsByTargetingId(
        updatedDataSet.feature_sets
      );
      if (withCaregiver && selectedAdditionalOpts) {
        setCareGiverSet({
          setId: withCaregiver.feature_set_id,
          orderId: withCaregiver.order_id,
          element: createCareGiverElem(
            withCaregiver.feature_set_id,
            withCaregiver.order_id
          ),
        });
      } else if (!withCaregiver && selectedAdditionalOpts) {
        setCareGiverSet({
          setId: 99,
          orderId: 99,
          element: createCareGiverElem(99, 99),
        });
      } else {
        setCareGiverSet({
          setId: 0,
          orderId: 0,
        });
        setCaregiverTargeting([]);
        setCaregiverFeatureSet({
          caregiverGender: null,
          caregiverAge: [],
          selectedGeographyDmas: [],
          selectedGeographyStates: [],
        });
      }
    }
  }, [selectedAdditionalOpts]);

  const createCareGiverElem = (setId: number, orderId: number) => {
    return (
      <CareGiverFeatureSet
        featureSetNumber={setId}
        selectedAdditionalOpts={selectedAdditionalOpts}
      />
    );
  };

  const createHcpElem = (setId: number, orderId: number) => {
    return (
      <HcpFeatureSet featureSetNumber={setId} featureSetOrderNumber={orderId} />
    );
  };

  const createGlobalElem = (setId: number, orderId: number) => {
    return (
      <GlobalFeatureSet
        featureSetNumber={setId}
        featureSetOrderNumber={orderId}
      />
    );
  };

  const createNewElem = (setId: number, orderId: number) => {
    return (
      <DefinedFeatureSet
        featureSetNumber={setId}
        featureSetOrderNumber={orderId}
        setDeletingSetId={setDeletingSetId}
      />
    );
  };

  const getOverAllData = () => {
    const newFeatureSets = Object.values(savingDataSet);
    const isEnableValues = Object.values(isEnableSaveForEachSet);

    // Determine if data preparation is needed
    const shouldPrepareData =
      ((newFeatureSets?.length ||
        !(
          defineFeatureData?.length &&
          isEnableValues.some((value) => value !== 0)
        )) &&
        !selectedAdditionalOpts &&
        isSaveInitiated) ||
      ((newFeatureSets?.length || selectedAdditionalOpts) &&
        isSaveInitiated &&
        isEnableValues.some((value) => value !== 0));

    // If no data preparation is needed, exit early
    if (!shouldPrepareData) {
      return;
    }

    // Filter feature sets with targeting
    const featureSetDetails = newFeatureSets.filter(
      (el: any) => el.targeting?.length
    );

    if (adminData.admin_option_id === 0) {
      adminData['is_updating'] = false;
    } else {
      adminData['is_updating'] = true;
    }

    // Prepare common data object
    const overAllData = {
      audience_id: audienceId,
      user_id: userInformation.user_id,
      audience_type_id: selectedAudienceType ?? 0,
      title: audienceTitle,
      agency_id: audienceAgency,
      brand_id: audienceBrand,
      advertiser_id: advertiser,
      status_id: selectedStatus,
      admin_options: adminData,
      pixel_id: pixelId === 0 ? null : pixelId,
      additional_option_id: selectedAdditionalOpts,
      feature_sets: featureSetDetails?.length ? featureSetDetails : [],
      is_edited: 0,
    };
    // Set the body with prepared data
    if (
      updatedDataSet.estimated_info &&
      updatedDataSet.estimated_info.length > 0
    ) {
      if (selectedSavingOpt === '3') {
        setAudiencePageNumber(2);
        setSelectedSavingOpt('');
      } else if (selectedSavingOpt === '4') {
        setAudiencePageNumber(3);
        setSelectedSavingOpt('');
      } else {
        setBody(overAllData);
      }
    } else {
      setBody(overAllData);
    }
  };

  const resetIsDataSavedState = () => {
    setTimeout(() => {
      setIsDataSaved(false);
      setShowBlankLoaderState(false);
    }, 500);
  };

  const eventAfterSave = (audienceId: number) => {
    const tempDdElem = [...targetingDDElements],
      usableDdElem = tempDdElem.filter((el: any) => !el.is_global);

    usableDdElem.sort((a: any, b: any) => a.order_id - b.order_id);
    const usableSelectedSavingOpt = selectedSavingOpt;
    switch (usableSelectedSavingOpt) {
      case '1': {
        if (!updatedDataSet) {
          resetAllStates();
          setIfUpdated(false);
          resetAllLocalStates();
          resetIsDataSavedState();
          setFocusOnTitle(1);
          setSelectedSavingOpt('');
        } else {
          updatedDataSet['status_id'] = 1;
          setUpdatedDataSet(updatedDataSet);
        }
        break;
      }
      case '2': {
        resetAllStates();
        setIfUpdated(false);
        resetAllLocalStates();
        resetIsDataSavedState();
        redirectAudienceListing();
        setFocusOnTitle(1);
        setSelectedSavingOpt('');
        break;
      }
      case '3': {
        setSelectedSavingOpt('');
        updatedDataSet['audience_id'] = audienceId;
        updatedDataSet['agency_id'] = audienceAgency;
        updatedDataSet['brand_id'] = audienceBrand;
        updatedDataSet['advertiser_id'] = advertiser;
        updatedDataSet['audience_type_id'] = selectedStatus;
        setUpdatedDataSet(updatedDataSet);
        setModalToOpen(true);
        break;
      }
    }
  };

  const handleAddFeature = () => {
    setDefineFeatureCount(defineFeatureCount + 1);
    setDefineFeatureData(createFeatureSet([], defineFeatureCount + 1));
  };

  return (
    <div className="featureSetWholeWrapBlock">
      {context}
      <Modal
        centered
        open={modalToOpen}
        onOk={() => {
          setAudiencePageNumber(2);
        }}
        onCancel={() => {
          setModalToOpen(false);
        }}
        okText="Yes"
        cancelText="No"
        maskClosable={false}
        className="alertSpecificClass"
        width={'330px'}
        closable={false}
      >
        <div className="modalGenericContent">
          <div className="modalGenericTextContent">
            <p>You are about to build insights for this audience. Proceed?</p>
          </div>
        </div>
      </Modal>
      <div className="audienceDefinitionWrap">
        <h3>Audience Definition</h3>
        {/* <MessageFormatter
          globalTargeting={globalTargeting}
          globalFeatureSet={globalFeatureSet}
          hcpTargeting={hcpTargeting}
          hcpFeatureSet={hcpFeatureSet}
          caregiverTargeting={caregiverTargeting}
          caregiverFeatureSet={caregiverFeatureSet}
          setGlobalMsg={setGlobalMsg}
        /> */}
        {userInformation.role === 4 && (
          <div className="featureSetWhole pixelUrlSec adminoptionAccordion">
            <Collapse
              bordered={false}
              defaultActiveKey={['']}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              expandIconPosition="end"
              items={getItems()}
            />
          </div>
        )}
        <div>{careGiverSet.element}</div>
        <div>{hcpFeaturesSet.element}</div>
        <div>{globalFeatureSets.element}</div>

        {defineFeatureCount > 0 &&
          defineFeatureData.map((el: IFeatureSetElement) => (
            <Fragment key={el.setId}>{el.element}</Fragment>
          ))}
      </div>
      {defineFeatureCount > 0 && (
        <div className="addNewFeaturesetbtn addPatientHealthAttribute">
          <Button
            onClick={handleAddFeature}
            className="secondaryBtn"
            disabled={defineFeatureCount >= 4}
          >
            <PlusOutlined />
          </Button>
        </div>
      )}
    </div>
  );
};

export default TargetableFeatureSetup;

const formatDataToCreateMessage = (params: {
  data: any;
  md?: any;
  tol: 0 | 1 | 2 | 3 | 4 | 5;
  parentId?: any;
  ck?: string;
}) => {
  const { data, md, tol, parentId, ck } = params;

  switch (tol) {
    // Single select
    case 1: {
      return (
        md.find((el: IEachDropdownElementsType) => el.value === data)?.label ??
        ''
      );
    }
    // Multi Select
    case 2: {
      return md
        .filter((el: IEachDropdownElementsType) => data.includes(el.value))
        ?.map((el: IEachDropdownElementsType) => el.label);
    }
    // Dual selector
    case 3: {
      try {
        const typeOfSelectedParent = Array.isArray(parentId);
        if (
          ((typeOfSelectedParent && parentId.length) ||
            (!typeOfSelectedParent && parentId)) &&
          ck
        ) {
          const childMasterDataNested = md
              .filter((el: any) =>
                !typeOfSelectedParent
                  ? el.value === parentId
                  : parentId.includes(el.value)
              )
              .map((el: any) => el[ck]),
            childMasterData = childMasterDataNested.concat(
              ...childMasterDataNested
            );

          return childMasterData
            .filter((el: IEachDropdownElementsType) => data.includes(el.value))
            ?.map((el: IEachDropdownElementsType) => el.label);
        }
        return null;
      } catch (error) {
        return null;
      }
    }
    case 4: {
      if (data?.length) {
        return 'All';
      }
      return null;
    }
    case 5: {
      const labelMap: any = Object.values(md)
        .flat()
        .reduce((acc: any, item: any) => {
          acc[item.value] = item.label;
          return acc;
        }, {});
      return data.map((item: any) => labelMap[item] || null);
    }
    default: {
      return data;
    }
  }
};
