import { useEffect, useState } from 'react';
import { IUserInformationState } from '../../../models/interfaces';
import { useUserInformationStore } from '../../../stores';
import PageHeader from '../../sharedComponent/PageHeader/PageHeader';
import LeftPanel from '../LeftNavPanel/LeftPanel';

import { Button } from 'antd';
import {
  useAuth,
  useCookies,
  usePostApiInterceptor,
  useNotificationMessage,
} from '../../../hooks';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import { UserType } from '../../../hooks/useCookies';
import { useNavigate } from 'react-router-dom';
import { ModalComponent } from '../../sharedComponent';
import { Auth } from 'aws-amplify';
import { ProfileAvatar, ProfileDetails } from './components';

const ProfilePage = () => {
  document.title = 'Profile - BranchLab';
  const { userInformation, setUserInformation } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    navigate = useNavigate(),
    { logout } = useAuth(),
    { deleteCookie, createCookie } = useCookies();

  const [userBody, setUserBody] = useState<any>(null),
    [userMounted, setUserMounted] = useState(false),
    [isLoading, setIsLoading] = useState(false),
    [user, setUser] = useState<any>(userInformation),
    [url, setUrl] = useState<string>(''),
    [isActive, setIsActive] = useState<boolean>(false),
    [resetMounted, setResetMounted] = useState(false),
    [resetBody, setResetBody] = useState<any>(null),
    [modalToOpen, setModalToOpen] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false });
    const regex = /^.{1,50}$/;

  const { context, destroyMessage } = useNotificationMessage(messageObj);

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration: 5,
    });
  };

  const handleSubmit = () => {
    destroyMessage('save');
    setMessageObj({ isShowing : false })
    setUserBody({
      uid: userInformation.user_id,
      email: userInformation.email,
      first_name: user.first_name,
      last_name: user.last_name,
      account_id: userInformation.account_id,
      profile_pic:
        !userInformation.profile_pic && url.length
          ? url
          : !url.length && !userInformation.profile_pic
          ? null
          : url.length && userInformation.profile_pic
          ? url
          : userInformation.profile_pic
    });
    setUserMounted(true);
    setIsLoading(true);
  };

  usePostApiInterceptor(
    userMounted,
    userBody,
    RoutesEnum.SAVE_USER,
    (data: any, error: any) => {
      setIsLoading(false);
      deleteCookie();
      if (data && !error) {
        updateErrorMessage('success', 'User is saved');
        let copy = {
        ...userInformation,
        user_id: userInformation.user_id,
        first_name: user.first_name,
        last_name: user.last_name,
        profile_pic: url.length ? url : userInformation.profile_pic,
      };
      const CookieData: UserType = {
        user_id: userInformation.user_id ?? null,
        email: userInformation.email ?? null,
        timestamp: userInformation.timestamp ?? null,
        user_role_id: userInformation.role ?? null,
        user_token: userInformation.token ?? null,
        profile_pic: url.length ? url : userInformation.profile_pic ?? null,
        first_name: user.first_name,
        last_name: user.last_name,
        user_account_id: userInformation.account_id ?? null,
        privilege_set: userInformation.privilege_set ?? null,
      };

      setUserInformation(copy);
      createCookie(CookieData);
      } else {
        setUrl('')
        setUser(userInformation)
        updateErrorMessage('error', 'Error occurred while saving User.');
      }
      setUserBody(null);
      setUserMounted(false);
    }
  );
  
  useEffect(() => {
    if (
      user.first_name !== userInformation.first_name ||
      user.last_name !== userInformation.last_name ||
      user.profile_pic !== userInformation.profile_pic ||
      url?.length
    ) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, [user, userInformation, url]);

  usePostApiInterceptor(
    resetMounted,
    resetBody,
    RoutesEnum.RESET_PASSWORD,
    (data: any, error: any) => {
      updateErrorMessage(
        'success',
        'A reset email has been sent to your registered address. You will be logged out shortly for security purposes'
      );
      setIsLoading(false);
      deleteCookie();
      if (data && !error) {
        Auth.signOut();
        logout();
      } else {
        updateErrorMessage(
          'error',
          'Error occurred while sending forgot password mail'
        );
      }
      setResetBody(null);
      setResetMounted(false);
    }
  );

  const handleCancel = () => {
    setModalToOpen(false);
  };

  const handleLogout = async () => {
    setResetBody({
      email: userInformation.email,
      first_name: userInformation.first_name,
    });
    setResetMounted(true);
  };

  return (
    <div className="audienceSetupPage">
      <LeftPanel />
      {context}
      <ModalComponent
        modalHeader={'Reset Password'}
        modalToOpen={modalToOpen}
        setModalToOpen={setModalToOpen}
        deleteSetParams={{
          name: 'resetPassword',
          handelOk: handleLogout,
          message:
            'A reset password link will be sent to your email. Please check your inbox (and spam folder) for the email. You will be logged out of the site for security reasons',
          okText: 'Send',
          handleCancel: handleCancel,
          cancelText: 'Cancel',
        }}
      />
      <div className="audienceCommonWrap">
        <PageHeader title="Profile" />
        <div className="profileScreenContentWrap">
          <ProfileAvatar userInfo={user} saveUser={setUser} saveUrl={setUrl} />
          <ProfileDetails userData={user} saveUser={setUser} />
        </div>
      </div>
      <div className="newFeatureSetBtn">
        <Button className="noBGBtn" size="large" onClick={()=>navigate(-1)}>
          Cancel
        </Button>
        <Button
          size="large"
          onClick={() => setModalToOpen(true)}
          className="secondaryBtn backBtn"
        >
          Reset Password
        </Button>
        <Button
          className="primaryBtn"
          size="large"
          disabled={isActive || !regex.test(user.first_name) || !regex.test(user.last_name)}
          loading={isLoading}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default ProfilePage;
