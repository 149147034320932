import React, { useEffect, useState } from 'react';
// import { ai } from '../../../../../assets/images';
import { useAudienceSets } from '../../../../../stores/audienceSets.store';
import {
  IAudienceState,
  IFeatureSetDataState,
  IMasterAdvertiserDDState,
  IMasterAgencyDDState,
  IMasterAudienceTypeDDState,
  IMasterBrandDDState,
} from '../../../../../models/interfaces';
import { ModalComponent, PageHeader } from '../../../../sharedComponent';
import { AudienceEstimateTable } from '../../../AudienceActivation/AudienceActivationComponent';
import {
  useFeatureSetDataHandlingStore,
  useMasterAudienceTypeElementsStore,
} from '../../../../../stores';
import {
  useNotificationMessage,
  usePostApiInterceptor,
} from '../../../../../hooks';
import { RoutesEnum } from '../../../../../models/enums/apiRoutes';
import {
  useMasterAdvertiserElementStore,
  useMasterAgencyElementStore,
  useMasterBrandElementStore,
} from '../../../../../stores/masterData.store';

type TEstimateBody = {
  audience_id: number;
  estimate_id: number;
};

const ReviewAudience = () => {
  const { audienceSummary } = useAudienceSets((state: IAudienceState) => state),
    { updatedDataSet, setUpdatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { agencyDDElements } = useMasterAgencyElementStore(
      (state: IMasterAgencyDDState) => state
    ),
    { brandDDElements } = useMasterBrandElementStore(
      (state: IMasterBrandDDState) => state
    ),
    { advertiserDDElements } = useMasterAdvertiserElementStore(
      (state: IMasterAdvertiserDDState) => state
    );

  const [estimateData, setEstimateData] = useState<any>([]),
    [showAudienceEstimate, setShowAudienceEstimate] = useState<boolean>(false),
    [isEstimateMounted, setIsEstimateMounted] = useState<boolean>(false),
    [disableEstimate, setDisableEstimate] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [modalToOpen, setModalToOpen] = useState<boolean>(false),
    [isReviewMounted, setIsReviewMounted] = useState(false),
    [isReviewBody, setIsReviewBody] = useState<{ audience_id: number } | null>(
      null
    ),
    [estimateSizeBody, setEstimateSizeBody] = useState<TEstimateBody | null>(
      null
    );

  const { context, destroyMessage } = useNotificationMessage(messageObj);

  const audienceTypeLookup = new Map(
    audienceTypeDDElements.map((item) => [item.value, item.label])
  );
  const agencyLookup = new Map(
    agencyDDElements.map((item) => [item.value, item.label])
  );
  const advertiserLookup = new Map(
    advertiserDDElements.map((item) => [item.value, item.label])
  );
  const brandLookup = new Map(
    brandDDElements.map((item) => [item.value, item.label])
  );

  useEffect(() => {
    if (updatedDataSet.estimated_info) {
      setShowAudienceEstimate(true);
      setEstimateData(updatedDataSet.estimated_info);
    } else if (
      updatedDataSet.estimated_info ||
      updatedDataSet.status_id !== 2
    ) {
      setModalToOpen(true);
    }
  }, [updatedDataSet]);

  const DestroyMessage = () => {
    destroyMessage('save');
    setMessageObj({ isShowing: false });
  };

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration: 5,
    });
  };

  usePostApiInterceptor(
    isEstimateMounted,
    estimateSizeBody,
    RoutesEnum.SAVE_ESTIMATE,
    (data: any, error: any) => {
      if (error || !data) {
        setDisableEstimate(false);
        updateErrorMessage('error', 'Error occurred while generating estimate');
        return;
      }
      setDisableEstimate(false);
      updateErrorMessage('success', 'Generating estimate successfully');
      setEstimateSizeBody(null);
      setIsEstimateMounted(false);
      setIsReviewMounted(true);
      setIsReviewBody({ audience_id: updatedDataSet.audience_id });
    }
  );

  usePostApiInterceptor(
    isReviewMounted,
    isReviewBody,
    RoutesEnum.GET_ACTIVATION,
    (data: any, error: any) => {
      setIsReviewMounted(false);
      setIsReviewBody(null);
      if (error || !data) {
        updateErrorMessage('error', 'Error occurred while generating review');
        return;
      }
      setDisableEstimate(false);
      if (data.estimated_info) {
        updatedDataSet['estimated_info'] = data.estimated_info;
        setShowAudienceEstimate(true);
        setEstimateData(data.estimated_info);
      }
      setUpdatedDataSet(updatedDataSet);
    }
  );

  const generateEstimate = () => {
    DestroyMessage();
    setEstimateSizeBody({
      audience_id: parseInt(updatedDataSet.audience_id ?? '0'),
      estimate_id:
        updatedDataSet.status_id === 2 && estimateData.length
          ? estimateData[0].estimate_id
          : 0,
    });
    setDisableEstimate(true);
    setIsEstimateMounted(true);
  };

  return (
    <>
      {context}
      <ModalComponent
        modalToOpen={modalToOpen}
        setModalToOpen={setModalToOpen}
        deleteSetParams={{
          handelOk: generateEstimate,
          message: 'The audience is about to be estimated. Proceed?',
          okText: 'Yes',
          cancelText: 'No',
          handleCancel: () => {
            setModalToOpen(false);
          },
        }}
      />
      <PageHeader
        title="Review Audience"
        componentSpecificClass="audienceActivationPageHeader"
        disabled={updatedDataSet?.activation_list?.length > 0}
      />
      <div className="infoElemWrap">
        <div className="chart1">
          <p className="reviewAudienceInfo">
            <span>
              <strong>Audience Name:</strong> {updatedDataSet.title}
            </span>
            <span>
              <strong>Type:</strong>{' '}
              {audienceTypeLookup.get(updatedDataSet.audience_type_id)}
            </span>
            <span>
              <strong>Agency:</strong>{' '}
              {agencyLookup.get(updatedDataSet.agency_id)}
            </span>
            <span>
              <strong>Advertiser:</strong>{' '}
              {advertiserLookup.get(updatedDataSet.advertiser_id)}
            </span>
            <span>
              <strong>Brand:</strong> {brandLookup.get(updatedDataSet.brand_id)}
            </span>
          </p>
          {/* <div className="d-flex">
            <img src={ai} alt="" />
            <h2>About this audience</h2>
          </div> */}
          <p>{audienceSummary}</p>
        </div>
      </div>
      <div className="audienceActivationTable mb-20">
        <AudienceEstimateTable
          isLoading={false}
          isActive={showAudienceEstimate}
          data={estimateData}
          disable={disableEstimate}
          generateEstimate={generateEstimate}
        />
      </div>
    </>
  );
};

export default ReviewAudience;
