/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, FC, Fragment, useState } from 'react';
import { useAuth, usePostApiInterceptor, useRedirection } from '../hooks';
import {
  useLoaderStore,
  useUserInformationStore,
  useMasterAudienceTypeElementsStore,
  useMasterGenderElementsStore,
  useMasterInsurancePlanTypeElementsStore,
  useMasterInsurancePlanProviderElementsStore,
  useMasterOperatorElementsStore,
  useMasterRaceAndEthnicityElementsStore,
  useMasterStateElementsStore,
  useMasterStatusElementsStore,
  useMasterReportsElementsStore,
  useMasterTargetingElementsStore,
  useMasterTimeFrameElementsStore,
  useMasterAdditionalOptionElementsStore,
  useMasterProviderSpecialtyElementsStore,
  useMasterProviderTaxonomyElementStore,
} from '../stores';
import {
  IEachDropdownElementsType,
  ILoaderState,
  IMasterAdditionalOptionDDState,
  IMasterAudienceTypeDDState,
  IMasterDestinationDDState,
  IMasterGenderDDState,
  IMasterInsurancePlanTypeDDState,
  IMasterInsurancePlanProviderDDState,
  IMasterOperatorDDState,
  IMasterProviderSpecialtyDDState,
  IMasterRaceAndEthnicityDDState,
  IMasterSspDDState,
  IMasterStateDDState,
  IMasterStatusDDState,
  IMasterTargetingDDState,
  IMasterCategoryDDState, 
  IMasterTimeFrameDDState,
  IUserInformationState,
  IMasterReportsDDState,
  IMasterLanguageDDState,
  IMasterAgencyDDState,
  IMasterAdvertiserDDState,
  IMasterBrandDDState,
  IMasterFilterDDState,
  IMasterRoleDDState,
  IMasterActionDDState,
  IMasterProviderTaxonomyDDState,
  IMasterPixelDDState,
  IMasterPartnerDDState,
  IMasterAdminDDState,
  IMasterPartnerListDDState
} from '../models/interfaces';
import { RoutesEnum } from '../models/enums/apiRoutes';
import {
  useMasterActionElementsStore,
  useMasterAdvertiserElementStore,
  useMasterAgencyElementStore,
  useMasterBrandElementStore,
  useMasterDestinationElementsStore,
  useMasterFilterElmentStore,
  useMasterLanguageElementStore,
  useMasterPixelElementsStore,
  useMasterSSPElementsStore,
  useMasterUserRoleElementsStore,
  useMasterPartnerElementsStore,
  useMasterCategoryElementsStore,
  useMasterAdminRoleElementsStore,
  useMasterPartnerListElementsStore
} from '../stores/masterData.store';

const AuthGuard: FC<{ component: JSX.Element }> = ({ component }) => {
  const [status, setStatus] = useState(false),
    [data, setData] = useState<any>(null);

  const { setLoaderState, isMounted, setIsMounted } = useLoaderStore(
      (state: ILoaderState) => state
    ),
    { setAudienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { setRoleDDElements } = useMasterUserRoleElementsStore(
      (state: IMasterRoleDDState) => state
    ),
    { setStatusDDElements } = useMasterStatusElementsStore(
      (state: IMasterStatusDDState) => state
    ),
    { setActionDDElements } = useMasterActionElementsStore(
      (state: IMasterActionDDState) => state
    ),
    { setAdminDDElements } = useMasterAdminRoleElementsStore(
      (state: IMasterAdminDDState) => state
    ),
    { setPixelDDElements } = useMasterPixelElementsStore(
      (state: IMasterPixelDDState) => state
    ),
    { setReportsDDElements } = useMasterReportsElementsStore(
      (state: IMasterReportsDDState) => state
    ),
    { setGenderDDElements } = useMasterGenderElementsStore(
      (state: IMasterGenderDDState) => state
    ),
    { setRaceAndEthnicityDDElements } = useMasterRaceAndEthnicityElementsStore(
      (state: IMasterRaceAndEthnicityDDState) => state
    ),
    { setStateDDElements } = useMasterStateElementsStore(
      (state: IMasterStateDDState) => state
    ),
    { setTargetingDDElements } = useMasterTargetingElementsStore(
      (state: IMasterTargetingDDState) => state
    ),
    { setLanguageDDElements } = useMasterLanguageElementStore(
      (state: IMasterLanguageDDState) => state
    ),
    { setPartnerDDElements } = useMasterPartnerElementsStore(
      (state: IMasterPartnerDDState) => state
    ),
    { setPartnerListDDElements } = useMasterPartnerListElementsStore(
      (state: IMasterPartnerListDDState) => state
    ),
    { setCategoryElements } = useMasterCategoryElementsStore(
      (state: IMasterCategoryDDState) => state
    ),
    { setAgencyDDElements } = useMasterAgencyElementStore(
      (state: IMasterAgencyDDState) => state
    ),
    { setAdvertiserDDElements } = useMasterAdvertiserElementStore(
      (state: IMasterAdvertiserDDState) => state
    ),
    { setFilterDDElements } = useMasterFilterElmentStore(
      (state: IMasterFilterDDState) => state
    ),
    { setBrandDDElements } = useMasterBrandElementStore(
      (state: IMasterBrandDDState) => state
    ),
    { setOperatorDDElements } = useMasterOperatorElementsStore(
      (state: IMasterOperatorDDState) => state
    ),
    { setInsurancePlanTypeDDElements } =
      useMasterInsurancePlanTypeElementsStore(
        (state: IMasterInsurancePlanTypeDDState) => state
      ),
    { setInsurancePlanProviderDDElements } =
      useMasterInsurancePlanProviderElementsStore(
        (state: IMasterInsurancePlanProviderDDState) => state
      ),
    { setTimeFrameDDElements } = useMasterTimeFrameElementsStore(
      (state: IMasterTimeFrameDDState) => state
    ),
    { setUserInformation, setUserIsLoggedIn, setPrivilegeSetDDElements } =
      useUserInformationStore((state: IUserInformationState) => state),
    { setAdditionalOptionDDElements } = useMasterAdditionalOptionElementsStore(
      (state: IMasterAdditionalOptionDDState) => state
    ),
    { setProviderSpecialtyDDElements } =
      useMasterProviderSpecialtyElementsStore(
        (state: IMasterProviderSpecialtyDDState) => state
      ),
    { setSspDDElements } = useMasterSSPElementsStore(
      (state: IMasterSspDDState) => state
    ),
    { setDestinationDDElements } = useMasterDestinationElementsStore(
      (state: IMasterDestinationDDState) => state
    ),
    { setProviderTaxonomyDDElements } = useMasterProviderTaxonomyElementStore(
      (state: IMasterProviderTaxonomyDDState) => state
    );

  const { getUserInfo } = useAuth(),
    { redirectLogin } = useRedirection();

  usePostApiInterceptor(
    isMounted,
    null,
    RoutesEnum.GET_MASTER_DATA,
    (baseData: any) => {
      if (baseData) {
        const {
          audience_type,
          gender,
          insurance_plan_type,
          operator,
          race_ethnicity,
          insurance_provider,
          state,
          status,
          language,
          targeting,
          timeframe,
          additional_option,
          provider_specialty,
          ssp,
          agency,
          brand,
          action,
          filter_options,
          advertiser,
          activation_destination,
          research_generate_fields,
          user_role,
          pixels,
          admin_options,
          provider_taxonomy,
          partner,
          category_list,
          partner_list
        } = baseData;
        setAdminDDElements(admin_options)
        setActionDDElements(action);
        setCategoryElements(category_list);
        setPartnerListDDElements(partner_list)
        setPartnerDDElements(partner);
        setFilterDDElements(filter_options);
        setAgencyDDElements(agency);
        setBrandDDElements(brand);
        setRoleDDElements(user_role);
        setAdvertiserDDElements(advertiser);
        setPixelDDElements(pixels)
        setTargetingDDElements(formatMasterData(targeting, 5));
        setStatusDDElements(formatMasterData(status, 2));
        // setReportsDDElements(formatMasterData(research_generate_fields, 2));
        setOperatorDDElements(formatMasterData(operator, 3));
        setInsurancePlanTypeDDElements(
          insurance_plan_type
        );
        setLanguageDDElements(language);
        setInsurancePlanProviderDDElements(insurance_provider);
        setAudienceTypeDDElements(formatMasterData(audience_type));
        setGenderDDElements(formatMasterData(gender));
        setRaceAndEthnicityDDElements(formatMasterData(race_ethnicity));
        setStateDDElements(formatMasterData(state));
        setTimeFrameDDElements(timeframe);
        // setAdditionalOptionDDElements(additional_option);
        setProviderSpecialtyDDElements(provider_specialty);
        // setSspDDElements(ssp);
        // setDestinationDDElements(activation_destination);
        setProviderTaxonomyDDElements(provider_taxonomy);

        setData(baseData);
      }
      setIsMounted(false);
      setLoaderState(false);
    }
  );

  useEffect(() => {
    checkCookie();
  }, [component]);

  const checkCookie = async () => {
    try {
      const userInfo = getUserInfo();
      if (!userInfo?.user_id) {
        redirectLogin();
      } else if (!data) {
        setLoaderState(true);
        setIsMounted(true);
      }

      if (userInfo) {
        setPrivilegeSetDDElements(userInfo.privilege_set);
        setUserInformation({
          last_name: userInfo.last_name,
          first_name: userInfo.first_name,
          timestamp: userInfo.timestamp,
          role: userInfo.user_role_id,
          user_id: userInfo.user_id,
          email: userInfo.email,
          profile_pic: userInfo.profile_pic,
          token: userInfo.user_token,
          account_id: userInfo.user_account_id,
          privilege_set: userInfo.privilege_set,
        });
        setUserIsLoggedIn(true);
      }

      setStatus(true);
      return;
    } catch (error) {
      redirectLogin();
    }
  };

  return status ? <Fragment>{component}</Fragment> : <></>;
};

export default AuthGuard;

const formatMasterData = (data: any, specificType: 0 | 1 | 2 | 3 | 4 | 5 = 0) => {
  switch (specificType) {
    case 1: {
      const temp = data.map((el: IEachDropdownElementsType) => {
        if ([4, 19].includes(Number(el.value))) {
          return {
            ...el,
            disabled: true,
            enableId: 1,
          };
        }
        if ([6, 7, 20].includes(Number(el.value))) {
          return {
            ...el,
            disabled: true,
            enableId: 2,
          };
        }

        if (el.value === 8) {
          return {
            ...el,
            disabled: true,
            enableId: 3,
          };
        }
        return {
          ...el,
        };
      });
      return temp;
    }
    case 2: {
      const temp = data.map((el: IEachDropdownElementsType) => {
        return {
          ...el,
          className: el.label.toLowerCase(),
        };
      });
      return temp;
    }
    case 3: {
      const temp = data.map((el: IEachDropdownElementsType) => {
        if (el.order_id === 1) {
          return {
            ...el,
            isDefault: true,
          };
        }
        return {
          ...el,
        };
      });
      return temp;
    }
    case 4: {
      const temp = [{ value: -1, label: 'All', order_id: 0 }, ...data];
      return temp;
    }
    case 5: {
      const temp = data.map((el: IEachDropdownElementsType) => {
        return {
          ...el,
          disabled : false
        };
      });
      return temp;
    }
    default:
      return data;
  }
};
