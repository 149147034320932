import { InputNumber, Switch } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useMasterAdminRoleElementsStore } from '../../../../stores/masterData.store';
import { IMasterAdminDDState } from '../../../../models/interfaces';

interface AdminOption {
  admin_option_id: number;
  is_search_for_treatments: boolean;
  drugs_count: number;
  is_distinct: boolean;
  updated_on?: string;
}

const AdminOptions: FC<{
  data: AdminOption;
  setData: React.Dispatch<React.SetStateAction<AdminOption>>;
  disabled?: boolean;
}> = ({ data, setData, disabled }) => {
  const updateField = <T extends keyof AdminOption>(
    field: T,
    value: AdminOption[T]
  ) => {
    setData((prev) => ({ ...prev, [field]: value }));
  };

  const { adminDDElements } = useMasterAdminRoleElementsStore(
    (state: IMasterAdminDDState) => state
  );

  return (
    <div>
      <div className="adminOptionElem">
        <p>Also search treatments for drugs</p>
        <Switch
          checked={data.is_search_for_treatments}
          disabled={disabled}
          onChange={(checked) =>
            updateField('is_search_for_treatments', checked)
          }
        />
      </div>
      <div className="adminOptionElem">
        <div className="adminOptionInnerElem">
          <p>Having Count (Drugs)</p>
          <InputNumber
            min={0}
            max={10}
            disabled={disabled}
            value={Math.min(data.drugs_count, 10)}
            controls={false}
            parser={(value) => Math.min(parseInt(value ?? '0', 10), 10)}
            formatter={(value) =>
              `${Math.min((value ?? 0), 10)}`.replace(/[^\d]/g, '')
            } 
            onChange={(value) =>
              updateField('drugs_count', Math.min(value ?? 0, 10))
            } 
          />
        </div>

        <div className="adminOptionInnerElem">
          <p>Distinct?</p>
          <Switch
            disabled={disabled}
            checked={data.is_distinct}
            onChange={(checked) => updateField('is_distinct', checked)}
          />
        </div>
      </div>
      <p className="updatedDataInfo">
        Health data last updated{' '}
        {dayjs.utc(adminDDElements[0].value).local().format('M/D/YYYY')}
      </p>
    </div>
  );
};

export default AdminOptions;
