import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Button, Input, Table, TableColumnsType } from 'antd';
import { LeftPanel } from '../LeftNavPanel';
import {
  CommonElementLabel,
  InputField,
  PageHeader,
} from '../../sharedComponent';
import { Col, Row } from 'antd';
import {
  useNotificationMessage,
  usePostApiInterceptor,
  useRedirection,
} from '../../../hooks';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import { useNavigate, useParams } from 'react-router-dom';
import { WarningOutlined } from '@ant-design/icons';
import { useCheckIfDataModifiedStore } from '../../../stores';
import { ICheckIfDataModifiedState } from '../../../models/interfaces';

interface IAccountData {
  account_name: string;
  account_admin_email: string;
}
type Tuser = {
  account_id: number;
  user_id: number;
  name: string;
  email: string;
  role_name: string;
  is_active: boolean;
};
interface IAccountInfoData {
  account_id: string;
}

interface DataType {
  account_id: number;
  user_id: number;
  name: string;
  email: string;
  role_name: string;
  is_active: boolean;
}

const AddAccount = () => {
  const { id } = useParams(),
    navigate = useNavigate();

  document.title = (id ? 'Update Account ' : 'Add Account ') + '- BranchLab';

  const [isLoading, setIsLoading] = useState<boolean>(false),
    [name, setName] = useState<string>(''),
    [email, setEmail] = useState<string>(''),
    nameRef = useRef<HTMLInputElement>(null),
    [accountInfoBody, setAccountInfoBody] = useState<IAccountInfoData | null>(
      null
    ),
    [isinitialLoading, setIsInitialLoading] = useState(false),
    [userList, setUserList] = useState<Tuser[]>([]),
    [isValid, setIsValid] = useState(false),
    [accountInfoMounted, setAccountInfoMounted] = useState<boolean>(false),
    [accountBody, setAccountBody] = useState<IAccountData | null>(null),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [accountMounted, setAccountMounted] = useState<boolean>(false);

  const { context, destroyMessage } = useNotificationMessage(messageObj);

  const { ifDataModified, setIfDataModified } = useCheckIfDataModifiedStore(
    (state: ICheckIfDataModifiedState) => state
  )

  useEffect(() => {
    if (id) {
      setIsInitialLoading(true);
      let accountData = {
        account_id: id,
      };
      setAccountInfoBody(accountData);
      setAccountInfoMounted(true);
    }
  }, [id]);

  usePostApiInterceptor(
    accountInfoMounted,
    accountInfoBody,
    RoutesEnum.GET_ACCOUNT_INFO,
    (data: any, error: any) => {
      setName(data.account_info.account_name);
      setEmail(data.account_info.email);
      setUserList(data.user_list);
      setAccountInfoBody(null);
      setIsInitialLoading(false);
      setAccountInfoMounted(false);
    }
  );

  usePostApiInterceptor(
    accountMounted,
    accountBody,
    RoutesEnum.SAVE_ACCOUNT,
    (data: any, error: any) => {
      setIsLoading(false);
      setAccountBody(null);
      setAccountMounted(false);
      setIfDataModified(false)
      if (data && !error) {
        updateErrorMessage('success', 'Account is saved');
      } else {
        updateErrorMessage('error', 'Error occurred while saving Account.');
      }
    }
  );

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration: 5,
    });
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '350px',
      render: (text, record) => <div>{text}</div>,
      sorter: false,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '250px',
      render: (text, record) => <div>{text}</div>,
      sorter: false,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      width: '200px',
      render: (text, record) => (
        <div>{record.is_active ? 'Active' : 'InActive'}</div>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role_name',
      key: 'role',
      width: '200px',
      render: (text, record) => <div>{text}</div>,
      sorter: false,
      ellipsis: true,
      showSorterTooltip: false,
    },
  ];

  const handleSubmit = () => {
    if (!validateEmail(email)) {
      setIsValid(true);
    } else {
      setMessageObj({
        key: 'save',
        isShowing: false,
        duration: 5,
      });
      setIsValid(false);
      setIsLoading(true);
      let accountData = {
        account_id: parseInt(id || ''),
        account_name: name,
        account_admin_email: email,
      };
      setAccountBody(accountData);
      setAccountMounted(true);
      destroyMessage('save');
    }
  };

  const handleCancel = () => {
    setName('');
    setEmail('');
    navigate('/accounts');
  };

  function validateEmail(email: string): boolean {
    // Regular expression for validating an email address
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return emailPattern.test(email);
  }

  return (
    <div className="audienceSetupPage">
      <LeftPanel defaultSelectedKeys={['6']} />
      {!isinitialLoading && (
        <Fragment>
          <div className="audienceCommonWrap">
            <PageHeader title={id ? 'Update Account' : 'Add Account'} />
            {context}
            <div className="addAccountForm">
              <Row>
                <Col span={24} className="mb-20">
                  <InputField
                    inputPlaceholder="Name"
                    fieldType="input"
                    label="Name"
                    isMandatory
                    defaultValue={name}
                    onChange={(data:any) => {
                      setName(data)
                      setIfDataModified(true)
                    }}
                    props={{ ref: nameRef }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <CommonElementLabel label="Admin Email" isMandatory />
                  <Input
                    disabled={id ? true : false}
                    value={email}
                    placeholder="Email"
                    status={isValid ? 'error' : ''}
                    required={true}
                    suffix={
                      isValid && (
                        <WarningOutlined className="validationErrorIcon" />
                      )
                    }
                    onChange={(e: any) => {
                      setIsValid(false);
                      setEmail(e.target.value);
                      setIfDataModified(true)
                    }}
                  />
                </Col>
              </Row>
            </div>
            {id && (
              <Table
                columns={columns}
                dataSource={userList}
                className={`antTableElement audienceListTable${
                  isLoading ? ' hideNoDataLabel' : ''
                }`}
                pagination={false}
                scroll={{ x: 'max-content' }}
              />
            )}
          </div>
          <div className="newFeatureSetBtn">
            <Button className="noBGBtn" size="large" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="primaryBtn"
              size="large"
              loading={isLoading}
              onClick={handleSubmit}
              disabled={(id ? !ifDataModified : (!name || !email))}
            >
              Save
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default AddAccount;
